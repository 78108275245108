@use "../base/summarize" as * ;
.breadcrumbs {
	overflow: auto;

	@include wide {
		position: relative;
		z-index: 1;
		width: 100%;
		max-width: calc(1200px + #{$side_padding_pc} * 2);
		margin-right: auto;
		margin-left: auto;
		padding-right: $side_padding_pc;
		padding-left: $side_padding_pc;
	}
	ol {
		display: inline-flex;
	}
	li {
		font-size: 1.3rem;
		display: flex;
		align-items: center;
		white-space: nowrap;

		@include wide {
			font-size: 1.2rem;
		}
		@include sp {
			&:first-child {
				padding-left: $side-padding-sp;
			}
			&:last-child {
				padding-right: $side-padding-sp;
			}
		}
		&:not(:last-child) {
			&:after {
				display: block;
				width: 8px;
				height: 8px;
				content: "";
				transform: rotate(45deg);
				border: solid $black;
				border-width: 1px 1px 0 0;
			}
		}
		a {
			display: block;
			padding: 18px 16px;
			color: inherit;
		}
		&:first-child a {
			padding-left: 0;
		}
		&:last-child a {
			font-weight: 600;
			padding-right: 0;
			pointer-events: none;
		}
		span {
			position: relative;
			display: inline-block;
			&:after {
				position: absolute;
				bottom: 2px;
				left: 0;
				display: block;
				width: 100%;
				height: 1px;
				content: "";
				transition: opacity .4s;
				opacity: 0;
				background: $black;
			}
		}
		a:hover span:after {
			@include wide {
				opacity: 1;
			}
		}
	}
	&--white {
		li {
			color: $white;
			&:not(:last-child) {
				&:after {
					border-color: $white;
				}
			}
			a span:after {
				background: $white;
			}
		}
	}
}
