@use "../base/summarize" as * ;
.page-history {
    background: transparent;
    .footer__pagetop {
        background: transparent;
    }
    &.bottom::before {
        background-position: center bottom;
    }
    &::before {
        background-image: url(/assets/img/company/history/main_sp.png);
        background-position: center 214px;
        background-repeat: no-repeat;
        background-size: cover;
        content: "";
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100vh;
        @include wide {
            background-image: url(/assets/img/company/history/main_pc.png);
            background-position: center 114px;
        }
    }
}

.history-mv {
    position: relative;
    height: 500px;
    @include wide {
        height: 680px;
    }
    &__img {
        position: absolute;
        width: 311px;
        top: 11.8%;
        left: 50%;
        transform: translateX(-50%);
        @include wide {
            width: 593px;
            left: 14.5833%;
            transform: translateX(0);
        }
    }
    &__text {
        position: absolute;
        font-size: 1.4rem;
        line-height: calc(24.5 / 14);
        font-weight: 700;
        top: 52.4%;
        left: 9.87%;
        @include wide {
            top: 63.4%;
            left: 15.3833%;
            font-size: 2rem;
            line-height: calc(40 / 20);
        }
    }
}

.history-content {
    background-color: $white;
    padding: 65px 0 80px;
    @include wide {
        padding: 120px 0 90px;
    }
    &__inner {
        width: calc(335 / 375 * 100%);
        margin: 0 auto;
        @include wide {
            max-width: 990px;
        }
        &--first {
            margin-bottom: 90px;
            @include wide {
                margin-bottom: 125px;
            }
        }
        &--second {
            margin-bottom: 50px;
            @include wide {
                margin-bottom: 150px;
            }
        }
    }
    &__wrap {
        width: calc(335 / 375 * 100%);
        margin: 0 auto;
        @include wide {
            max-width: 1041px;
        }
    }
}

.history__subtitle {
    position: relative;
    font-size: 2rem;
    font-weight: 700;
    line-height: calc(30 / 20);
    margin-bottom: 25px;
    padding-left: 28px;
    &::before {
        position: absolute;
        content: '';
        background-color: $red;
        height: 4px;
        width: 24px;
        top: 50%;
        left: 0;
        transform: translateY(-50%) rotate(-45deg);
    }
}

.history__text {
    font-size: 1.6rem;
    line-height: calc(28 / 16);
    @include wide {
        font-size: 1.8rem;
    }
    a {
        font-weight: 700;
        color: $red;
    }
}

.history__title {
    text-align: center;
    font-size: 2.4rem;
    line-height: calc(36 / 24);
    font-weight: 700;
    margin-bottom: 78px;
    letter-spacing: .1em;
    @include wide {
        font-size: 4rem;
        margin-bottom: 100px;
    }
    span {
        display: block;
        font-weight: 400;
        color: $red;
        margin-bottom: 20px;
        letter-spacing: 0;
        font-family: $font_barlow;
        @include wide {
            font-size: 2.8rem;
        }
    }
}

.history-work {
    position: relative;
    &__content {
        @include wide {
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    &__images {
        margin-top: 25px;
        @include wide {
            margin-top: 0;
        }
    }
}

.history-change {
    @include wide {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
    }
    &__content {
        @include wide {
            width: calc(472 / 990 * 100%);
        }
    }
    &__images {
        margin-top: 40px;
        @include wide {
            margin-top: 0;
            width: calc(448 / 990 * 100%);
        }
    }
}

.history-list {
    position: relative;
    @include wide {
        display: flex;
    }
    &__item {
        position: relative;
        font-size: 1.6rem;
        line-height: calc(24 / 16);
        text-align: center;
        padding: 60px 0 35px;
        box-shadow: 0px 32px 70px -50px rgba($color: #958F8F, $alpha: .5);
        @include wide {
            box-shadow: 52px 0px 30px -50px rgba($color: #958F8F, $alpha: .5);
            padding: 0 0 90px;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        &::before {
            position: absolute;
            content: '';
            left: 50%;
            bottom: 0;
            transform: translate(-50%, 100%);
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 14px 13px 0 13px;
            border-color: $red transparent transparent transparent;
            @include wide {
                border-width: 13px 0 13px 14px;
                border-color: transparent transparent transparent $red;
                left: auto;
                right: 0;
                bottom: auto;
                top: 50%;
                transform: translate(100%, -50%);
            }
        }
        &:last-of-type {
            box-shadow: none;
            &::before {
                display: none;
            }
        }
        &:nth-of-type(1) {
            @include wide {
                width: 32.325%;
                padding-top: 100px;
            }
        }
        &:nth-of-type(2) {
            @include wide {
                width: 35.35%;
                padding-top: 60px;
            }
        }
        &:nth-of-type(3) {
            @include wide {
                width: 32.325%;
                padding-top: 80px;
            }
        }
    }
    &__logo {
        margin-left: auto;
        margin-right: auto;
        &--01 {
            width: 141px;
            margin-bottom: 35px;
            @include wide {
                width: 200px;
                margin-bottom: 60px;
            }
        }
        &--02 {
            width: 150px;
            margin-bottom: 33px;
            @include wide {
                width: 200px;
                margin-bottom: 38px;
            }
        }
        &--03 {
            width: 200px;
            margin-bottom: 21px;
            @include wide {
                width: 240px;
                margin-bottom: 41px;
            }
        }
    }
}