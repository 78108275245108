@use "../base/summarize" as * ;
.accordion-box__body {
	transition: opacity .2s;
	.accordion-box:not(.is-active) & {
		overflow: hidden;
		width: 0;
		height: 0;
		margin: 0;
		padding: 0;
		opacity: 0;
	}
}
.accordion-box__plus {
	position: relative;
	width: 16px;
	height: 16px;
	&:before,
	&:after {
		position: absolute;
		position: absolute;
		top: calc(50% - 2px);
		top: 0;
		left: 0;
		display: block;
		width: 2px;
		height: 16px;
		content: "";
		transition: .3s;
		background: $red;
		@at-root .accordion-box.is-active & {
			transform: rotate(90deg);
		}
	}
	&:before {
		margin-top: -7px;
		margin-left: 7px;
	}
	&:after {
		width: 16px;
		height: 2px;
		@at-root .accordion-box.is-active & {
			opacity: 0;
		}
	}
}



// 採用などで使用するアコーディオンのスタイル

.primary-accordion {
	padding: 15px $side-padding-sp 15px 47px;
	transition: box-shadow .3s;
	background: $white;

	@include wide {
		padding: 15px 17px 15px 76px;
		&:hover {
			box-shadow: $box_shadow;
		}
	}
	&.is-active {
		box-shadow: $box_shadow;
	}
	& +& {
		margin-top: 10px;

		@include wide {
			margin-top: 20px;
		}
	}
}
.primary-accordion__inner {
	width: 100%;
	height: 100%;

	@include wide {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
	}
}
.primary-accordion__head {
	position: relative;
	display: flex;
	align-items: center;

	@include wide {
		flex: 1;
		cursor: pointer;

		order: 1;
	}
	&:before {
		display: block;
		height: 50px;
		content: "";
	}
	.accordion-box__plus {
		position: absolute;
		top: calc(50% - 1px);
		left: -28px;

		@include wide {
			left: -55px;
		}
	}
}
.primary-accordion__heading {
	font-size: 1.6rem;
	position: relative;

	@include wide {
		font-size: 1.8rem;
		transition: color .3s;
	}
	.primary-accordion.is-active & {
		color: $red;
	}
	.primary-accordion__head:hover & {
		@include wide {
			color: $red;
		}
	}
}
.primary-accordion__outline {
	width: 100%;
	margin-top: 10px;

	@include wide {
		margin-bottom: 15px;
		padding-right: 140px;

		order: 3;
	}
}
.primary-accordion__text {
	@include wide {
		font-size: 1.6rem;
	}
}
.primary-accordion__annotation {
	font-size: 1.2rem;
}
.primary-accordion__button {
	display: flex;
	flex-wrap: wrap;

	@include sp {
		margin-top: 10px;
		padding-bottom: 20px;
	}
	@include wide {
		flex: none;
		margin-right: 0;
		margin-left: auto;

		order: 2;
	}
	.primary-accordion:not(.is-active) & {
		@include sp {
			display: none;
		}
	}
}
.primary-accordion__button__item {
	margin-top: 10px;
	margin-right: 12px;

	@include wide {
		margin-top: 0;
	}
	&:last-child {
		margin-right: 0;
	}
}
