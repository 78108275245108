@use "../base/summarize" as * ;
// mv

.interview-module-mv {
	height: 375px;

	@include wide {
		height: 600px;
	}
	.under-mv__inner {
		@include sp {
			align-items: flex-end;
			padding-bottom: 50px;
		}
	}
	.under-mv__heading {
		font-size: 2.4rem;
		line-height: 1.5;

		@include sp {
			padding-left: 30px;
		}
		@include wide {
			font-size: 4rem;
		}
		&--small {
			font-size: 2rem;
			@include wide {
				font-size: 3.8rem;
			}
		}
	}
	.under-mv__image {
		img {
			font-family: "object-fit: cover;object-position: 80% 0;";

			object-fit: cover;
			object-position: 80% 0;
		}
	}
}
.interview-module-mv__text {
	font-family: $font_barlow_c;
	font-size: 1.4rem;
	font-weight: 600;
	display: block;
	margin-top: 12px;
	letter-spacing: .1em;

	@include wide {
		font-size: 2.0rem;
		margin-top: 40px;
		letter-spacing: .05em;
	}
	&--top {
		margin-top: 0;
		margin-bottom: 5px;

		@include wide {
			margin-top: 0;
			margin-bottom: 20px;
		}
	}
}

// パーツ

.interview-module-lead {
	font-size: 1.6rem;
	line-height: 1.75;
	margin-top: 60px;
	letter-spacing: .02em;

	@include wide {
		font-size: 1.8rem;
		line-height: 2;
	}
}
.interview-module-lead__notes {
	font-size: 1.2rem;
	display: block;
	margin-top: 10px;

	@include wide {
		font-size: 1.4rem;
	}
}
.interview-module-lead__link {
	color: $red;
	font-weight: bold;
	text-decoration: none;
	&:hover {
		text-decoration: underline;
	}
}
.interview-module-heading {
	font-size: 2.4rem;
	line-height: 1.5;
	margin-bottom: 20px;
	letter-spacing: .1em;

	@include wide {
		font-size: 2.8rem;
		margin-bottom: 35px;
	}
	a {
		color: $red;
		font-weight: bold;
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
}
.interview-module-sub-heading {
	font-size: 2.0rem;
	line-height: 1.5;
	margin-bottom: 30px;
	letter-spacing: .05em;

	@include wide {
		margin-bottom: 45px;
		letter-spacing: .1em;
	}
}
.interview-module-talk {
	font-size: 1.8rem;
	line-height: 1.75;

	@include wide {
		line-height: 2;
		display: flex;
	}
	&:not(:last-child) {
		margin-bottom: 25px;

		@include wide {
			margin-bottom: 45px;
		}
	}
}
.interview-module-talk__name {
	@include sp {
		margin-bottom: 4px;
	}
	@include wide {
		flex: none;
		width: 102px;
	}
}
.interview-module-talk__text a {
	color: $red;
	font-weight: bold;
	text-decoration: none;
	cursor: pointer;
	&:hover {
		text-decoration: underline;
	}
}
.interview-module-talk__subtitle {
	font-size: 1.8rem;
	font-weight: 700;
	line-height: 175%;
	margin-top: 1.8rem;
	@include wide {
		line-height: 200%;
	}
}
.interview-module-wideimage {
	@include sp {
		margin-top: 50px;
		margin-right: -$side-padding-sp;
		margin-left: -$side-padding-sp;
	}
	@include wide {
		margin-top: 70px;
	}
}
.interview-module-flexbox {
	padding-right: $side-padding-sp;
	padding-left: $side-padding-sp;

	@include wide {
		padding-right: $side-padding-pc;
		padding-left: $side-padding-pc;
	}
	@include pc {
		display: flex;
		justify-content: flex-end;
	}
	@media (min-width: (1072px)) {
		margin-left: calc((100% - 992px) / 2 - #{$side-padding-pc});
	}
	@media (min-width: (1440px)) {
		max-width: 1110px;
		margin-right: auto;
		margin-left: auto;
	}
}
.interview-module-flexbox__image {
	margin-top: 50px;

	@include wide {
		width: 70%;
		max-width: 384px;
		margin-right: auto;
		margin-left: auto;
	}
	@include pc {
		flex: none;
		width: 384px;
		max-width: 100%;
		margin-top: 30px;
		margin-right: -$side-padding-pc;
		margin-left: 48px;
	}
	@media (min-width: (1440px)) {
		margin-right: -200px;
	}
}
.interview-module-flexbox__outline {
	@include pc {
		flex: 1;
	}
}
.interview-module-flexbox__image__block {
	width: calc(50% + 12px);

	@include pc {
		width: 72.397%;
	}
	&--02 {
		margin-top: -31%;
		margin-right: 0;
		margin-left: auto;

		@include pc {
			margin-top: -40px;
		}
	}
	&--03 {
		margin-top: 30px;
		margin-right: auto;
		margin-left: auto;

		@include pc {
			margin-top: 86px;
			margin-right: 28px;
		}
	}
}

// section - explain

.interview-module-explain {
	padding-top: 60px;
	padding-bottom: 52px;

	@include wide {
		padding-top: 80px;
		padding-bottom: 70px;
	}
}
.interview-module-explain__inner {
	@include wide {
		max-width: calc(1088px + #{$side-padding-pc} * 2);
	}
	&--second {
		@include pc {
			max-width: calc(1088px + 194px);
			padding-left: 148px;
			padding-right: 48px;
		}
	}
}

// section - member

.interview-module-member {
	@include pc {
		display: flex;
		justify-content: center;
	}
}
.interview-module-member__block {
	@include pc {
		width: calc(100% / 3);
		text-align: center;
	}
	&:not(:last-child) {
		@include mobile {
			margin-bottom: 40px;
		}
	}
	&:first-child {
		@include pc {
			text-align: left;
		}
	}
	&:last-child {
		@include pc {
			text-align: right;
		}
	}
}
.interview-module-member__block__inner {
	display: flex;

	@include pc {
		display: inline-block;
	}
}
.interview-module-member__image {
	flex: none;
	width: 80px;
	margin-right: 20px;

	@include wide {
		width: 100%;
		max-width: 152px;
	}
	@include pc {
		margin-right: auto;
		margin-bottom: 16px;
		margin-left: auto;
	}
}
.interview-module-member__name {
	font-size: 1.6rem;
	font-weight: 600;
	line-height: 1.5;

	@include wide {
		font-size: 1.8rem;
		letter-spacing: .1em;
	}
	@include pc {
		text-align: center;
	}
	span {
		font-size: 1.4rem;
		font-weight: normal;
		display: block;
		margin-bottom: 3px;

		@include wide {
			letter-spacing: .02em;
		}
	}
}
.interview-module-member__text {
	font-size: 1.2rem;
	line-height: 1.5;
	margin-top: 10px;
	text-align: left;
	letter-spacing: .02em;

	@include wide {
		line-height: 1.75;
		margin-top: 12px;
	}
	@include pc {
		max-width: 256px;
		margin-right: auto;
		margin-left: auto;
	}
}
.interview-module-member--second {
	@include pc {
		flex-wrap: wrap;
	}
	.interview-module-member__block {
		@include pc {
			width: 50%;
			&:nth-of-type(1) {
				margin-bottom: 65px;
			}
			&:nth-of-type(2) {
				margin-bottom: 65px;
			}
		}
	}
	.interview-module-member__block__inner {
		@include pc {
			width: 100%;
			display: flex;
			align-items: center;
		}
	}
	.interview-module-member__text {
		margin-top: 4px;
		display: flex;
		span {
			white-space: nowrap;
			word-break: keep-all;
		}
		@include pc {
			max-width: 100%;
			width: 100%;
			justify-content: flex-start;
		}
	}
	.interview-module-member__name {
		@include pc {
			text-align: left;
		}
	}
	.interview-module-member__image {
		@include pc {
			width: 152px;
			margin: 0;
		}
	}
	.interview-module-member__outline {
		@include pc {
			width: calc(100% - 152px);
			padding-right: 5%;
			padding-left: 15px;
		}
	}
}

// section - main

.interview-module-main {
	padding-top: 80px;
	background: $white;

	@include wide {
		padding-top: 120px;
	}
}
.interview-module-main__block {
	&:not(:first-child) {
		margin-top: 120px;
	}
	&.section-inner {
		@include wide {
			max-width: calc(992px + #{$side_padding_pc} * 2);
		}
	}
}

// section - message

.interview-module-message {
	padding-top: 125px;
	padding-bottom: 64px;
	background: $white;

	@include wide {
		padding-bottom: 200px;
	}
}

// back button

.interview-module-back {
	padding-bottom: 120px;
	background: $white;

	@include wide {
		padding-bottom: 80px;
	}
	.basic-button {
		margin-right: auto;
		margin-left: auto;
	}
}

// ページ固有アイテム

.careers-brand-enhancement-teams-02 {
	.interview-module-explain {
		.interview-module-lead {
			margin: 0 0 25px 0;
			@include wide {
				text-align: center;
				margin: 0 0 35px 0;
			}
		}
		.interview-module-heading {
			margin: 0 0 50px 0;
			@include wide {
				text-align: center;
				margin: 0 0 70px 0;
			}
		}
	}
}

// おこしやす

.interview-module-subtitle {
	text-align: center;
	font-size: 2.4rem;
	font-weight: bold;
	margin-bottom: 15px;
	@include pc {
		font-size: 2.8rem;
		margin-bottom: 20px;
	}
}
.interview-module-subtext {
	text-align: center;
	font-size: 1.8rem;
	font-weight: bold;
	margin-bottom: 35px;
	@include pc {
		font-size: 2rem;
		margin-bottom: 60px;
	}
}
.interview-module-card {
	margin-bottom: 50px;
	@include pc {
		display: flex;
		justify-content: space-between;
		max-width: 880px;
		margin: 0 auto 70px;
	}
}
.interview-module-card__inner {
	box-shadow: 10px 10px 40px rgba(16, 15, 24, 0.1);
	padding: 20px;
	margin-bottom: 30px;
	@include pc {
		width: 47.2%;
		padding: 30px;
		margin-bottom: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
	}
	&:last-of-type {
		margin-bottom: 0;
	}
}
.interview-module-card__title {
	text-align: center;
	font-weight: bold;
	font-size: 2rem;
	margin-bottom: 10px;
	@include pc {
		font-size: 2.4rem;
		margin-bottom: 20px;
	}
}
.interview-module-card__text {
	font-size: 1.4rem;
	margin-bottom: 30px;
	@include pc {
		font-size: 1.6rem;
		margin-bottom: 40px;
	}
}