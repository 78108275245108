@use "../base/summarize" as * ;
.careers-faq__inner {
	margin-bottom: 90px;

	@include wide {
		margin-bottom: 140px;
	}
}

//ここからアンカーリンク
.careers-faq__menuarea {
	margin-bottom: 61px;

	@include wide {
		margin-bottom: 91px;
	}
}
.careers-faq__heading-top {
	font-size: 2.8rem;
	line-height: 1.5;
	margin-bottom: 54px;
	text-align: center;
	letter-spacing: .05em;

	@include wide {
		font-size: 3.6rem;
		line-height: 1.75;
		margin-bottom: 68px;
		padding-top: 42px;
		letter-spacing: .1em;
	}
}
// .careers-faq__menu {
// 	@include wide {
// 		display: flex;
// 		justify-content: space-between;
// 	}
// }
// .careers-faq__item {
// 	height: 60px;

// 	@include wide {
// 		width: 100%;
// 		max-width: 264px;
// 		margin-right: 48px;
// 	}
// }
// .careers-faq__item:last-of-type {
// 	@include wide {
// 		margin-right: 0;
// 	}
// }
// .careers-faq__item a::after {
// 	@include wide {
// 		right: 16px;
// 		width: 8px;
// 		height: 8px;
// 	}
// }

//ここからセクション毎
.careers-faq__entry,
.careers-faq__after,
.careers-faq__environment {
	max-width: 335px;
	margin-right: auto;
	margin-bottom: 80px;
	margin-left: auto;

	@include wide {
		max-width: 1200px;
		margin-bottom: 86px;
	}
}
.careers-faq__feature {
	max-width: 335px;
	margin-right: auto;
	margin-left: auto;

	@include wide {
		max-width: 1200px;
	}
}
.careers-faq__heading {
	font-size: 2rem;
	line-height: 1.5;
	margin-bottom: 22px;
	text-align: left;
	letter-spacing: .05em;

	@include wide {
		font-size: 2.4rem;
	}
}
.careers-faq__accordion {
	padding-bottom: 16px;
	border-top: 1px solid #e3dbdb;

	@include wide {
		padding-bottom: 23px;
		padding-left: 30px;
	}
}
.careers-faq__accordion:last-of-type {
	border-bottom: 1px solid #e3dbdb;
}
.careers-faq__accordion__head {
	display: flex;
	align-items: center;
	justify-content: space-between;

	@include pc {
		&:hover {
			cursor: pointer;
		}
	}
}
.careers-faq__accordion__heading {
	position: relative;
	width: 100%;
	max-width: 286px;
	// margin-right: 33px;
	padding-top: 16px;
	padding-left: 39px;
	letter-spacing: 0;

	@include wide {
		font-size: 1.8rem;
		max-width: 1091px;
		padding-top: 23px;
		padding-left: 75px;
		transition: color .4s;
		letter-spacing: .05em;
	}
	.accordion-box.is-active & {
		color: $red;
	}
	.careers-faq__accordion__head:hover & {
		@include wide {
			color: $red;
		}
	}
	&::before {
		font-family: $font_roboto;
		font-size: 2.4rem;
		line-height: .92;
		position: absolute;
		top: 19px;
		left: 0;
		content: "Q.";
		letter-spacing: .01em;

		@include wide {
			font-size: 3.4rem;
			line-height: .8;
			top: 26px;
			letter-spacing: .02em;
		}
	}
}
.careers-faq__plus {
	// width: auto;
	width: 49px;
	height: auto;

	@include wide {
		width: 79px;
	}

	&::before,
	&::after {
		top: 5px;
		left: auto;

		@include wide {
			top: 11px;
		}
		@at-root .accordion-box.is-active & {
			transform: rotate(90deg);
		}
	}
	&::before {
		right: 7px;

		@include wide {
			right: 37px;
		}
	}
	&::after {
		right: 0;

		@include wide {
			right: 30px;
		}
	}
}
.careers-faq__accordion__outline {
	padding-top: 26px;
	padding-left: 39px;

	@include wide {
		padding-top: 33px;
	}
}
.careers-faq__accordion__text {
	font-size: 1.6rem;
	position: relative;
	max-width: 260px;
	margin-right: 40px;
	letter-spacing: .05em;

	@include wide {
		font-size: 1.8rem;
		max-width: 1050px;
		margin-right: 79px;
		padding-left: 34px;
	}
	&::before {
		font-family: $font_roboto;
		font-size: 2.4rem;
		font-weight: bold;
		line-height: 1.15;
		position: absolute;
		top: 0;
		left: -39px;
		content: "A.";
		letter-spacing: .01em;

		@include wide {
			font-size: 3.4rem;
			line-height: .8;
			top: 8px;
			letter-spacing: .02em;
		}
	}
	&--none {
		&::before {
			display: none;
		}
	}
}
.careers-faq__accordion__text-annotation {
	font-size: 1.2rem;
	max-width: 260px;
	margin-right: 40px;
	letter-spacing: 0;

	@include wide {
		font-size: 1.4rem;
		max-width: 1050px;
		margin-right: 79px;
		padding-left: 34px;
		letter-spacing: .05em;
	}
}
.careers-faq__back {
	padding-top: 60px;
	padding-bottom: 18px;
	background: #fff;

	@include wide {
		padding-top: 122px;
		padding-bottom: 72px;
	}
}
.careers-faq__accordion-text-link {
	position: relative;
    display: inline;
    color: #e60000;
    font-weight: bold;
	word-break: break-all;


	&-external {
		padding-right: 1.8rem;
		&::after {
			position: absolute;
			top: 0.6rem;
			right: 0;
			content: '';
			background-image: url(/assets/img/common/icon-external-red.svg);
			background-size: contain;
			background-repeat: no-repeat;
			width: 1.2rem;
			height: 1.2rem;
			margin-right: 0.4rem;
			@include wide {
				top: 0.8rem;
			}
		}
	}

	&:hover {
		text-decoration: underline;
	}
}