@use "../base/summarize" as * ;
//ここからcareers-office__gallery
.careers-office__gallery {
	background: #fff;
}
.careers-office__gallery__inner {
	padding-top: 60px;
	padding-bottom: 68px;

	@include wide {
		padding-top: 120px;
		padding-bottom: 127px;
	}
}
.careers-office__gallery__textarea {
	margin-bottom: 42px;
	text-align: center;

	@include wide {
		margin-bottom: 54px;
	}
}
.careers-office__gallery__heading-top {
	font-size: 2.4rem;
	line-height: 1.5;
	margin-bottom: 25px;
	letter-spacing: .1em;

	@include wide {
		font-size: 2.8rem;
		margin-bottom: 29px;
	}
}
.careers-office__gallery__text {
	font-size: 1.8rem;
	line-height: 1.75;
	letter-spacing: .02em;
}
.careers-office__gallery__slider__content {
	.slick-list {
		overflow: visible;
	}
	.slick-slide {
		width: 78.5vw;

		@include pc {
			width: 832px;
		}
	}
	.slick-arrow {
		&.slick-prev {
			@include sp {
				left: 0px;
			}
		}
		&.slick-next {
			@include sp {
				right: 0px;
			}
		}
	}
}
.careers-office__gallery__slider__block p {
	font-size: 1.4rem;
	line-height: 1.75;
	padding-top: 8px;
	text-align: center;
	letter-spacing: .02em;

	@include wide {
		font-size: 1.6rem;
		line-height: 1.5;
		padding-top: 23px;
	}
}
.careers-office__gallery__slider__block__inner {
	padding: 0 15px;

	@include wide {
		padding-right: 24px;
		padding-left: 24px;
	}
}
.careers-office__gallery__slider__utility {
	margin-top: 20px;

	@include wide {
		margin-top: 19px;
	}
	.slick-dots {
		justify-content: space-between;
		width: 100%;
		max-width: 212px;

		@include wide {
			max-width: 392px;
		}
	}
	.slick-dots li {
		width: 11px;
		height: 20px;
		margin-right: auto;
		margin-left: auto;

		@include wide {
			width: 28px;
			height: 40px;
		}
	}
	.slick-dots li button {
		font-size: 6rem;
		margin-right: 0;
		margin-left: 0;
		padding-left: 0;
	}
	.slick-dots li button:first-of-type {
		padding-left: 0;
	}
	.slick-dots li button:last-of-type {
		padding-right: 0;
	}
	.slick-dots li button:after {
		width: 5px;
		height: 5px;
		margin-right: auto;
		margin-left: auto;

		@include wide {
			width: 8px;
			height: 8px;
		}
	}
}

//ここからcareers-office__entry
.careers-office__entry {
	margin-bottom: 60px;
	padding-top: 80px;

	@include wide {
		margin-bottom: 138px;
		padding-top: 90px;
	}
}
.careers-office__entry__heading-top {
	margin-bottom: 38px;

	@include wide {
		margin-bottom: 68px;
	}
}
.careers-office__entry__inner {
	padding-top: 2px;
}
.careers-office__entry__box {
	margin-bottom: 16px;
	transition: box-shadow .3s;
	background: #fff;

	@include wide {
		margin-bottom: 20px;
		&:hover {
			box-shadow: $box_shadow;
		}
	}
	&.is-active {
		box-shadow: $box_shadow;
	}
}
.careers-office__entry__box:last-of-type {
	margin-bottom: 0;
}
.careers-office__entry__head {
	display: flex;
	align-items: center;
	padding-top: 13px;
	padding-right: 16px;
	padding-bottom: 15px;
	padding-left: 16px;

	@include wide {
		padding-top: 21px;
		padding-right: 42px;
		padding-bottom: 25px;
		padding-left: 42px;
	}
}
.careers-office__entry__head:hover {
	@include pc {
		cursor: pointer;
	}
	.careers-office__entry__heading {
		@include pc {
			color: $red;
		}
	}
}
.careers-office__entry__image {
	width: 32px;
	height: 32px;
	margin-right: 10px;

	@include wide {
		width: 60px;
		height: auto;
		margin-right: 18px;
	}
}
.careers-office__entry__image picture {
	width: 100%;
	height: 100%;
}
.careers-office__entry__heading {
	position: relative;
	width: calc(100% - 54px);
	letter-spacing: .05em;

	@include wide {
		font-size: 2rem;
		line-height: .8;
		width: calc(100% - 74px);
		transition: color .4s;
	}
}
.careers-office__entry__plus {
	width: auto;
	height: auto;
	&::before,
	&::after {
		top: -1px;
		left: -10px;
	}
	&::before {
		@include wide {
			top: -3px;
			left: -16px;
			height: 20px;
		}
	}
	&::after {
		@include wide {
			top: -1px;
			left: -18px;
			width: 20px;
		}
	}
}
.careers-office__entry__wrap {
	@include pc {
		width: auto;
		margin-right: auto;
		margin-left: auto;
	}
	@media screen and (max-width: 1239px) {
		max-width: 858px;
	}
	@media screen and (min-width: 1240px) {
		max-width: none;
	}
}
.careers-office__entry__outline {
	@include pc {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		margin-right: auto;
		margin-left: auto;
		padding-top: 14px;
		padding-bottom: 52px;
	}
}
.careers-office__entry__outline__box {
	margin-right: 20px;
	margin-left: 20px;
	padding-top: 16px;
	padding-bottom: 20px;
	border-bottom: 1px solid #e3dbdb;

	@include pc {
		width: 100%;
		max-width: 286px;
		margin-right: 0;
		margin-left: 0;
		padding-top: 8px;
		padding-right: 30px;
		padding-bottom: 11px;
		padding-left: 30px;
		border-right: 1px solid #e3dbdb;
		border-bottom: none;
	}
}
.careers-office__entry__outline__box:first-of-type {
	padding-top: 3px;

	@include pc {
		padding-top: 8px;
	}
}
.careers-office__entry__outline__box:nth-of-type(n+4) {
	@media screen and (max-width: 1239px) {
		margin-top: 11px;
	}
}
.careers-office__entry__outline__box:nth-of-type(n+5) {
	@media screen and (min-width: 1240px) {
		margin-top: 11px;
	}
}
.careers-office__entry__outline__box:nth-of-type(3n) {
	@media screen and (max-width: 1239px) {
		border-right: none;
	}
}
.careers-office__entry__outline__box:nth-of-type(4n) {
	@media screen and (min-width: 1240px) {
		border-right: none;
	}
}
.careers-office__entry__outline__box:last-of-type {
	border-bottom: none;
}
.careers-office__entry__title {
	font-size: 1.6rem;
	margin-bottom: 7px;
	letter-spacing: .1em;

	@include pc {
		margin-bottom: 13px;
	}
}
.careers-office__entry__text {
	font-size: 1.4rem;
	line-height: 1.75;
	letter-spacing: .02em;
	a{
		font-weight: bold;
	}
}

//ここからcareers-office__goal
.careers-office__goal {
	background: url("/assets/img/careers/goal-bg_sp.jpg");
	background-size: cover;
	@include wide {
		background-image: url("/assets/img/careers/goal-bg_pc.jpg");
	}
}
.careers-office__goal__inner {
	padding-top: 82px;
	padding-bottom: 107px;
	@include wide {
		padding-top: 120px;
		padding-bottom: 140px;
	}
}
.careers-office__goal__content {
	background: #fff;
	padding:50px 20px;
	@include wide {
		padding: 80px 40px;
	}
}
.careers-office__goal__heading {
	font-size: 2.4rem;
	line-height: 1.5;
	margin-bottom: 34px;
	letter-spacing: .1em;

	@include wide {
		font-size: 2.8rem;
		margin-bottom: 58px;
		text-align: center;
	}
}
.careers-office__goal__text {
	font-size: 1.8rem;
	line-height: 1.75;
	margin-bottom: 37px;
	letter-spacing: .02em;

	@include wide {
		line-height: 2;
		max-width: 988px;
		letter-spacing: .018em;
		margin-right: auto;
		margin-left: auto;
	}
}
.careers-office__goal__text:last-of-type {
	margin-bottom: 0;
}

//ここからcareers-office__lead
.careers-office__lead {
	background: #fff;
}
.careers-office__lead__inner {
	padding-top: 65px;
	//padding-bottom: 76px;

	@include wide {
		padding-top: 115px;
		//padding-bottom: 160px;
	}
	@include pc {
		max-width: 1088px;
	}
}
.careers-office__lead__headingarea {
	margin-bottom: 55px;

	@include wide {
		margin-bottom: 68px;
	}
}
.careers-office__lead__heading-sub {
	font-size: 2.4rem;
	line-height: 1.5;
	margin-bottom: 24px;
	text-align: center;
	letter-spacing: .1em;

	@include wide {
		font-size: 2.8rem;
		margin-bottom: 40px;
	}
}
.careers-office__lead__heading {
	font-size: 1.6rem;
	line-height: 1.75;
	text-align: center;
	letter-spacing: .02em;
	font-weight: normal;
	@include wide {
		font-size: 1.8rem;
	}
}
.careers-office__lead__block {
	margin-bottom: 60px;

	@include pc {
		display: flex;
		align-items: center;
		margin-bottom: 100px;
	}
}
.careers-office__lead__block:last-of-type {
	margin-bottom: 0;
}
.careers-office__lead__image {
	width: 193px;
	margin-right: auto;
	margin-bottom: 22px;
	margin-left: auto;

	@include wide {
		width: 240px;
	}
	@include pc {
		margin-right: 72px;
		margin-bottom: 0;
		margin-left: 0;
	}
}
.careers-office__lead__image picture {
	width: 100%;
	height: 100%;
}
.careers-office__lead__textarea {
	@include pc {
		max-width: 680px;
	}
}
.careers-office__lead__headarea {
	margin-bottom: 21px;

	@include pc {
		display: flex;
		align-items: center;
		margin-bottom: 12px;
	}
}
.careers-office__lead__head {
	font-size: 2rem;
	font-weight: 700;
	line-height: 1.5;
	margin-bottom: 2px;
	text-align: center;
	letter-spacing: .05em;

	@include wide {
		font-size: 2.4rem;
		margin-bottom: 0;
		letter-spacing: .1em;
	}
	@include pc {
		margin-right: 27px;
	}
}
.careers-office__lead__head-sub {
	font-size: 1.6rem;
	line-height: 1.5;
	text-align: center;
	letter-spacing: .02em;

	@include wide {
		font-size: 1.8rem;
		letter-spacing: .1em;
	}
	@include pc {
		padding-top: 4px;
	}
}
.careers-office__lead__text {
	font-size: 1.6rem;
	line-height: 1.75;
	letter-spacing: .02em;
	@include wide {
		font-size: 1.8rem;
	}
}

//ここからcareers-office__container
.careers-office__container {
	background: #f4f4f4;
}
.careers-office__container__inner {
	width: 100%;
	padding-top: 60px;

	@include pc {
		display: flex;
		align-items: center;
		justify-content: space-between;
		max-width: 1264px;
		min-height: 400px;
		margin-right: 0;
		padding-top: 0;
		padding-right: 0;
	}
	@media screen and (min-width: 1441px) {
		margin-right: auto;
		padding-right: 48px;
	}
}
.careers-office__container__textarea {
	@include pc {
		width: 398px;
		padding-right: 48px;
	}
}
.careers-office__container__heading {
	font-size: 2.4rem;
	line-height: 1.5;
	margin-bottom: 18px;
	letter-spacing: .1em;

	@include wide {
		font-size: 2.8rem;
		margin-bottom: 26px;
	}
	@include pc {
		white-space: nowrap;
	}
}
.careers-office__container__text {
	font-size: 1.6rem;
	line-height: 1.75;
	margin-bottom: 29px;
	text-align: center;
	letter-spacing: .02em;

	@include wide {
		font-size: 1.8rem;
		margin-bottom: 43px;
	}
	@include pc {
		text-align: left;
		white-space: nowrap;
	}
}
.careers-office__container__button {
	max-width: 193px;
	height: 40px;
	margin-right: auto;
	margin-left: auto;
	@include pc {
		max-width: 207px;
		height: 48px;
		margin-left: 0;
	}
}
.careers-office__container__button span {
	font-size: 1.4rem;
}
.careers-office__container__image {
	width: 100%;
	height: auto;

	@include wide {
		max-width: 696px;
		margin-right: auto;
		margin-left: auto;
	}
	@include pc {
		margin-right: 0;
		margin-left: 0;
	}
}
.careers-office__container__image picture {
	@include pc {
		display: flex;
		align-items: flex-end;
		height: 100%;
	}
}

//container-1
.careers-office__container-1 {
	background: url("/assets/img/careers/office/image_workstyle_sp.jpg") no-repeat center bottom / cover;
	padding-bottom: calc(50vw + 30px);
	@include wide {
		background-position: bottom 10% left 50%;
	}
	@include pc {
		background-image: url("/assets/img/careers/office/image_workstyle_pc.jpg");
		background-position: right 25% top 50%;
		padding-bottom: 0;
	}
	.careers-office__container__heading {
		text-align: center;

		@include pc {
			text-align: left;
		}
	}
	.careers-office__container__button {
		margin-top: 50px;
		@include pc {
			margin-top: 0;
		}
	}
}

//container-2
.careers-office__container-2 {
	background: #fff;
	.careers-office__container__heading {
		text-align: center;

		@include pc {
			text-align: left;
		}
	}
	.careers-office__container__inner {
		@include pc {
			align-items: flex-end;
		}
	}
	.careers-office__container__textarea {
		@include pc {
			padding-bottom: 72px;
		}
	}
	.careers-office__container__button {
		margin-bottom: 48px;
		@include pc {
			margin-bottom: 0;
		}
	}
}

.careers-office__back {
	margin-top: 0;
	padding-top: 112px;
	padding-bottom: 86px;
	background: #fff;

	@include wide {
		padding-top: 102px;
		padding-bottom: 100px;
	}
}
