@use "../base/summarize" as * ;
.careers-message {
	position: relative;
	background-color: $white;
	&__inner {
		width: 90%;
		max-width: 992px;
		margin: 0 auto;
		padding-top: 80px;
		@include wide {
			padding-top: 100px;
		}
	}
	&__title {
		font-weight: 700;
		font-size: 28px;
		line-height: 1.5;
		text-align: center;
		letter-spacing: 0.05em;
		margin-bottom: 100px;
		@include wide {
			font-size: 36px;
			line-height: 1.75;
		}
	}
	&__subtitle {
		font-weight: 700;
		font-size: 24px;
		line-height: 1.5;
		letter-spacing: 0.1em;
		margin-bottom: 28px;
		@include wide {
			font-size: 28px;
		}
	}
	&__text {
		font-weight: 400;
		font-size: 18px;
		line-height: 1.75;
		letter-spacing: 0.02em;
		@include wide {
			font-size: 18px;
			line-height: 2;
		}
		&--mb {
			margin-bottom: 80px;
			@include wide {
				margin-bottom: 100px;
			}
		}
	}
	&__img {
		margin-bottom: 30px;
		@include wide {
			margin-bottom: 64px;
		}
	}
	&__person {
		display: flex;
		align-items: center;
		margin-top: 60px;
		padding-top: 40px;
		border-top: 1px solid #C8CDD6;
		@include wide {
			justify-content: center;
			margin-top: 84px;
			padding-top: 67px;
		}
	}
	&__person-img {
		width: 122px;
		margin-right: 19px;
		@include wide {
			width: 160px;
			margin-right: 48px;
		}
	}
	&__person-name {
		font-weight: 700;
		font-size: 20px;
		line-height: 1.5;
		letter-spacing: 0.02em;
		@include wide {
			font-size: 24px;
		}
		span {
			font-weight: 400;
			font-size: 14px;
			display: block;
			margin-bottom: 6px;
			@include wide {
				margin-bottom: 4px;
			}
		}
	}
}