@use "../base/summarize" as * ;
//ここからcompany-enza-mv
.company-enza-mv {
	height: 280px;

	@include wide {
		height: 400px;
	}
}
.company-enza-mv__inner {
	align-items: unset;
	padding-bottom: 0;

	@include wide {
		max-width: 1188px;
	}
}
.company-enza-mv__heading {
	position: relative;

	@include sp {
		font-size: 2.4rem;
		line-height: 1.5;
		padding-top: 5px;
	}
	@include wide {
		font-size: 3.2rem;
		width: 100%;
		padding-top: 28px;
		letter-spacing: .02em;
	}
}
.company-enza-mv__heading span {
	font-size: 1.4rem;
	line-height: 1;
	position: absolute;
	top: 10px;
	display: block;

	@include wide {
		top: 51px;
	}
}

//ここからcompany-enza-container
.company-enza-container {
	padding-top: 48px;
	padding-bottom: 102px;

	@include wide {
		margin-bottom: 0;
		padding-top: 104px;
		padding-bottom: 136px;
	}
}
.company-enza-container__inner {
	@include wide {
		position: relative;
		max-width: 976px;
	}
}
.company-enza-container__heading {
	font-size: 2.4rem;
	margin-bottom: 46px;
	letter-spacing: .1em;

	@include wide {
		font-size: 2.8rem;
		position: absolute;
		top: 63px;
		margin-bottom: 0;
	}
}
.company-enza-container__box {
	@include wide {
		display: flex;
		flex-direction: row-reverse;
		justify-content: space-between;
	}
}
.company-enza-container__image {
	max-width: 200px;
	margin-right: auto;
	margin-bottom: 70px;
	margin-left: auto;

	@include wide {
		max-width: 252px;
		margin-right: 0;
		margin-bottom: 0;
		margin-left: 0;
	}
}
.company-enza-container__textarea {
	@include wide {
		padding-top: 136px;
		padding-right: 48px;
	}
}
.company-enza-container__text {
	font-size: 1.8rem;
	line-height: 1.75;
	margin-bottom: 32px;
	letter-spacing: .02em;

	@include wide {
		line-height: 2;
		margin-bottom: 42px;
	}
}
.company-enza-container__button {
	max-width: 206px;
	height: 48px;
}

//ここからcompany-enza-title
.company-enza-title {
	padding-top: 58px;
	padding-bottom: 75px;
	background: #fff;

	@include pc {
		padding-top: 98px;
	}
}
.company-enza-title__inner {
	@include tab {
		width: 100%;
	}
}
.company-enza-title__heading {
	font-size: 2rem;
	margin-bottom: 27px;
	letter-spacing: .05em;

	@include wide {
		font-size: 2.4rem;
		margin-bottom: 30px;
	}
}
.company-enza-title__box {
	@include pc {
		display: flex;
	}
	@include tab {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		width: 100%;
		margin-right: auto;
		margin-left: auto;
	}
}
.company-enza-title__item {
	margin-bottom: 29px;
	padding-bottom: 30px;
	box-shadow: 10px 10px 40px rgba(16, 15, 24, .1);

	@include pc {
		width: calc((100% - 48px) / 3);
		margin-right: 48px;
		margin-bottom: 0;
	}
	@include tab {
		width: calc(50% - 24px);
		margin-right: 48px;
	}
}
.company-enza-title__item:nth-of-type(2n) {
	@include tab {
		margin-right: 0;
	}
}
.company-enza-title__item:last-of-type {
	margin-bottom: 0;

	@include pc {
		margin-right: 0;
	}
}
.company-enza-title__image {
	margin-bottom: 25px;

	@include pc {
		margin-bottom: 35px;
	}
}
.company-enza-title__wrap {
	max-width: 335px;
	margin-right: auto;
	margin-left: auto;
	padding-right: 20px;
	padding-left: 20px;

	@include pc {
		max-width: 368px;
		padding-right: 28px;
		padding-left: 28px;
	}
}
.company-enza-title__titlearea {
	line-height: 1;
	margin-bottom: 23px;

	@include pc {
		margin-bottom: 0;
	}
}
.company-enza-title__title {
	font-size: 1.6rem;
	margin-bottom: 13px;
	letter-spacing: 0;

	@include pc {
		font-size: 1.8rem;
	}
}
.company-enza-title__title-sub {
	font-size: 1.4rem;
	font-weight: normal;
}
.company-enza-title__textarea {
	line-height: 1;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	margin-bottom: 12px;

	@include pc {
		height: 47px;
		margin-bottom: 10px;
	}
}
.company-enza-title__text {
	font-size: 1.2rem;

	@include pc {
		margin-bottom: 3px;
	}
	@include tab {
		margin-bottom: 3px;
	}
}
.company-enza-title__text:last-of-type {
	@include pc {
		margin-bottom: 0;
	}
}
.company-enza-title__contentarea {
	display: flex;
	margin-right: 4px;
	margin-bottom: 25px;

	@include pc {
		margin-right: 2px;
	}
}
.company-enza-title__content {
	font-size: 1rem;
	line-height: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	max-width: 100px;
	height: 28px;
	margin-right: 5px;
	text-align: center;
	border: 1px solid #e3dbdb;

	@include pc {
		padding: 0 6px;
	}
}
.company-enza-title__content:last-of-type {
	margin-right: 0;
}
.company-enza-title__buttonarea {
	display: flex;
	justify-content: space-between;
}
.company-enza-title__button {
	width: 100%;
	height: 40px;
	margin-right: 10px;
	color: #100f18;
	border: 1px solid #e3dbdb;
	a:hover {
		@include wide {
			color: $red;
		}
	}
	.basic-button__inner {
		padding-bottom: 2px;
	}
}
.company-enza-title__button:last-of-type {
	margin-right: 0;
}
.company-enza-title__button__text {
	font-size: 1.2rem;
}
.company-enza-title__line {
	// @include pc {
	// 	display: flex;
	// 	flex-direction: row;
	// }
	@include tab {
		flex-direction: column;
	}
	@media screen and (max-width: 374px) {
		display: flex;
		flex-direction: column;
	}
	@media screen and (min-width: 758px) {
		flex-direction: row;
	}
	@media screen and (min-width: 1025px) {
		flex-direction: column;
	}
	@media screen and (min-width: 1211px) {
		flex-direction: row;
	}
}
