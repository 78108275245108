@use "../base/summarize" as * ;
.page-heading {
	font-size: 2.8rem;
	line-height: 1.5;
	padding-top: 25px;
	text-align: center;
	letter-spacing: .05em;

	@include wide {
		font-size: 3.6rem;
		padding-top: 70px;
		letter-spacing: .1em;
	}
}
.section-heading {
	font-size: 2.4rem;
	font-weight: 700;
	line-height: 1.5;
	text-align: center;
	letter-spacing: .1em;

	@include wide {
		font-size: 2.8rem;
	}
}
