@use "../base/summarize" as * ;
.under-mv {
	position: relative;
	display: flex;
	flex-direction: column;
	height: 210px;

	@include wide {
		height: 400px;
	}
}
.under-mv__inner {
	display: flex;
	flex: 1;
	align-items: center;
	padding-bottom: 40px;

	@include wide {
		width: 100%;
		max-width: calc(1200px + #{$side_padding_pc} * 2);
		margin-right: auto;
		margin-left: auto;
		padding-right: $side_padding_pc;
		padding-left: $side_padding_pc;
	}
}
.under-mv__image {
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	display: block;
	width: 100%;
	height: 100%;
	picture {
		width: 100%;
		height: 100%;
	}
	img {
		@include objectFit(cover);
		object-position: bottom;
	}
}
.under-mv__heading {
	font-size: 2.8rem;
	padding-left: 40px;
	letter-spacing: .05em;
	color: #fff;

	@include wide {
		font-size: 3.6rem;
		width: 100%;
		max-width: 1096px;
		margin-right: auto;
		margin-left: auto;
		padding-left: 0;
		letter-spacing: .1em;
	}
}
