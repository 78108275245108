@use "../base/summarize" as * ;
// 汎用

.careers-division-heading {
	line-height: 1.5;
	letter-spacing: .1em;
	@include sp {
		font-size: 2rem;
		margin-bottom: 40px;
		padding-left:$side_padding_sp;
		padding-right:$side_padding_sp;
	}
	@include wide {
		font-size: 2.8rem;
		margin-bottom: 45px;
		margin-right: auto;
		margin-left: auto;
		max-width: 1200px;
	}
	&--white{
		margin-bottom: 0;
		padding: 0;
		color: $white;
	}
}

// mv

.careers-division-mv {
	@include wide {
		height: 300px;
	}
	.under-mv__heading {
		color: $black;

		@include sp {
			font-size: 2.4rem;
			padding-left: 20px;
			letter-spacing: .1em;
		}
	}
	.side-fix-button {
		top: 210px;

		@include wide {
			top: 262px;
		}
	}
}

// intro

.careers-division-intro {
	padding-top: 40px;
	background: $white;

	@include wide {
		padding-top: 90px;
	}
}
// .careers-division-intro--pb {
// 	padding-bottom: 60px;

// 	@include wide {
// 		padding-bottom: 120px;
// 	}
// }
.careers-division-lead {
	font-size: 1.8rem;
	line-height: 1.75;
	letter-spacing: .02em;

	@include wide {
		max-width: 988px;
		margin-right: auto;
		margin-left: auto;
	}
	strong {
		font-weight: 600;
	}
	a {
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
	}
	&--small {
		font-size: 1.4rem;
	}
}
.careers-division-intro__figure {
	margin-top: 45px;

	@include wide {
		margin-top: 90px;
	}
}
.careers-division-intro__figure__image {
	@include sp {
		margin-right: -$side-padding-sp;
		margin-left: -$side-padding-sp;
	}
}
.careers-division-intro__figure__heading {
	font-size: 1.6rem;
	line-height: 1.5;
	margin-bottom: 10px;
	text-align: center;
	letter-spacing: .1em;

	@include wide {
		font-size: 2rem;
	}
}

// organization

.careers-division-organization {
	padding: 40px 20px 52px;
	background: $bg_gray;

	@include pc {
		padding: 65px 56px 70px;
	}
}
.careers-division-organization__logo {
	max-width: 243px;
	margin-right: auto;
	margin-left: auto;
	margin-bottom: 30px;

	@include pc {
		max-width: 438px;
		margin-bottom: 50px;
	}
}
.careers-division-organization__category {
	display: flex;
	flex-wrap: wrap;

	@include pc {
		justify-content: center;
	}
}
.careers-division-organization__category__block {
	width: calc((100% - 15px) / 2);
	min-height: 110px;
	margin-bottom: 15px;
	padding: 20px 15px;
	color: $white;
	background: #000;

	@include wide {
		padding: 26px 30px 22px;
	}
	@include pc {
		width: calc((100% - 96px) / 4);
		margin-bottom: 0;
		margin-right: 32px;
	}
	&:nth-child(odd) {
		margin-right: 15px;

		@include pc {
			margin-right: 32px;
		}
	}
	&:last-of-type {
		position: relative;
		width: 100%;
		margin-bottom: 0;
		@include pc {
			width: calc((100% - 96px) / 4);
		}
	}
	&:nth-of-type(1) {
		@include pc {
			order: 3;
			margin-right: 0;
		}
	}
	&:nth-of-type(2) {
		@include pc {
			order: 2;
		}
	}
	&:nth-of-type(3) {
		@include pc {
			order: 1;
		}
	}
	&--current {
		background: $red;
	}
}
.careers-division-organization__category__title {
	font-size: 1.3rem;
	margin-bottom: 7px;

	@include wide {
		font-size: 2rem;
		line-height: 1.5;
		margin-bottom: 12px;
		letter-spacing: .05em;
	}
}
.careers-division-organization__category__text {
	font-size: 1rem;
	font-weight: 600;
	line-height: 1.25;
	padding-left: 1em;
	text-indent: -1em;
	letter-spacing: 0;

	@include wide {
		font-size: 1.6rem;
		letter-spacing: .02em;
	}
	&:not(:last-child) {
		margin-bottom: 3px;

		@include wide {
			margin-bottom: 8px;
		}
	}
}
.careers-division-organization__category--line {
	&::before {
		position: absolute;
		content: '';
		background-color: $black;
		width: 2px;
		height: 25px;
		left: 50%;
		bottom: 0;
		transform: translate(-50%, 100%);
	}
	&::after {
		position: absolute;
		content: '';
		background-color: $black;
		width: 50%;
		height: 2px;
		left: 0;
		bottom: -25px;
		@include pc {
			width: 106%;
			transform: translateX(-53%);
		}
	}
}
.careers-division-organization__explanation {
	position: relative;
	&::before {
		position: absolute;
		content: '';
		width: 2px;
		height: calc(89% + 22px);
		background-color: $black;
		top: -22px;
		left: 0;
		@include wide {
			height: calc(88.3% + 22px);
		}
		@include pc {
			height: calc(84% + 76px);
			top: -76px;
		}
	}
	&--data {
		@include pc {
			padding-left: 50px;
		}

	}
}
.careers-division-organization__explanation__box {
	margin-top: 45px;
	padding-left: 20px;
	@include pc {
		margin-top: 50px;
		padding-left: 0;

		width: 100%;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 20px;
	}
	&:first-of-type {
		@include pc {
			margin-top: 100px;
		}
	}
}
.careers-division-organization__explanation__name {
	position: relative;
	font-weight: bold;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 48px;
	margin-bottom: 18px;
	color: $red;
	border: 3px solid $red;

	@include wide {
		font-size: 2rem;
		height: 70px;
		margin-bottom: 27px;
		letter-spacing: .05em;
		border: 4px solid $red;
	}
	@include pc {
		text-align: center;
		line-height: 1.2;
	}
	&::before {
		position: absolute;
		content: '';
		width: 20px;
		height: 2px;
		background-color: $black;
		top: 50%;
		transform: translateY(-50%);
		left: -22px;
		@include wide {
			left: -24px;
		}
		@include pc {
			left: -53px;
			width: 40px;
		}
	}
	&::after {
		position: absolute;
		content: '';
		width: 8px;
		height: 8px;
		top: 50%;
		transform: translateY(-50%) rotate(45deg);
		left: -6px;
		background-color: $black;
		@include pc {
			left: -13px;
		}
	}
}
.careers-division-organization__explanation__name--bg {
	position: relative;
	&::before {
		content: '';
		position: absolute;
		background-color: $bg_gray;
		width: 25px;
		height: 100px;
		top: calc(50% + 1px);
		left: -25px;
		@include pc {
			top: 36px;
			width: 55px;
			left: -55px;
		}
	}
}
.careers-division-organization__explanation__name-wrap {
	display: flex;
	justify-content: space-between;
	p {
		width: calc(50% - 9px);
	}
}
.careers-division-organization__explanation__name-sub {
	font-size: 1.2rem;
	font-weight: bold;
	line-height: 1.5;
	margin-bottom: 16px;
	letter-spacing: .02em;

	@include wide {
		font-size: 1.6rem;
		line-height: 1.75;
		text-align: center;
	}
}
.careers-division-organization__explanation__name-sub--show {
	@include pc {
		position: relative;
		right: -54%;
	}
}
.careers-division-organization__explanation__name-sub--hidden {
	@include pc {
		opacity: 0;
	}
}
.careers-division-organization__explanation__content {
	@media screen and (min-width: 1360px) {
		display: flex;
		flex-wrap: wrap;
	}
}
.careers-division-organization__explanation__item {
	margin-bottom: 20px;
	letter-spacing: .02em;
	background: #fff;
	box-shadow: $box_shadow;

	@include wide {
		// margin-bottom: 0px;
		cursor: pointer;
		transition: box-shadow .3s;
		// height: fit-content;
		&:hover {
			box-shadow: $box_shadow_hover;
		}
	}
	&:last-of-type {
		margin-bottom: 0;
	}
	.careers-division-organization__explanation__content & {
		@media screen and (min-width: 1360px) {
			width: calc(50% - 9px);
			&:nth-child(odd) {
				margin-right: 18px;
			}
		}
	}
	&.empty {
		background: transparent;
		box-shadow: none;
	}
}
.careers-division-organization__explanation__link {
	position: relative;
	display: block;
	padding-top: 19px;
	padding-right: 20px;
	padding-bottom: 17px;
	padding-left: 20px;

	@include wide {
		padding-top: 26px;
		padding-bottom: 24px;
	}
}
.careers-division-organization__explanation__heading-area {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 13px;
	padding-right: 30px;

	@include wide {
		margin-bottom: 28px;
	}
}
.careers-division-organization__explanation__heading {
	letter-spacing: 0;

	@include wide {
		font-size: 2rem;
		letter-spacing: .02em;
	}
}
.careers-division-organization__explanation__icon {
	position: absolute;
	top: 30px;
	right: 20px;
	display: block;
	width: 20px;
	height: 8px;
	background: url("/assets/img/common/arrow-next-red.svg") center center;
	background-size: contain;
	background-repeat: no-repeat;

	@include wide {
		top: 40px;
	}
	@include pc {
		transition: transform .2s;
		transform-origin: right;
	}
	a:hover & {
		@include wide {
			transform: scaleX(.85);
		}
	}
}
.careers-division-organization__explanation__title {
	margin-bottom: 3px;

	@include wide {
		font-size: 1.6rem;
	}
}
.careers-division-organization__explanation__text {
	font-size: 1.3rem;
	padding-left: 1em;
	text-indent: -1em;

	@include wide {
		font-size: 1.6rem;
	}
	span {
		font-size: 1.1rem;
		vertical-align: baseline;

		@include wide {
			font-size: 1.4rem;
		}
	}
}

// spec

.careers-division-spec {
	padding-top: 77px;
	background: $white;

	@include wide {
		padding-top: 167px;
	}
}
.careers-division-spec__inner {
	padding: 78px 0;
	background: url("/assets/img/careers/data-strategy/spec-bg_sp.jpg") center top;
	background-size: cover;

	@include wide {
		padding: 100px 0;
		background-image: url("/assets/img/careers/data-strategy/spec-bg_pc.jpg") ;
	}
}
.careers-division-spec__heading {
	font-size: 2rem;
	font-weight: 700;
	line-height: 1.5;
	letter-spacing: .05em;

	@include wide {
		font-size: 2.4rem;
	}
}
.careers-division-spec__content {
	@include pc {
		display: flex;
	}
}
.careers-division-spec__block {
	width: 100%;
	padding: 30px 30px 35px;
	background: $white;
	box-shadow: $box_shadow;

	@include wide {
		padding: 56px;
	}
	&:not(:last-child) {
		margin-bottom: 23px;

		@include pc {
			margin-right: 48px;
			margin-bottom: 0;
		}
	}
	&--data {
		padding-bottom: 52px;

		@include wide {
			padding-bottom: 40px;
		}
	}
}
.careers-division-spec__table {
	margin-top: 30px;

	@include wide {
		margin-top: 35px;
	}
	tr,
	th,
	td {
		@include sp {
			display: block;
			width: 100%;
		}
	}
	tr {
		&:not(:last-child) {
			@include sp {
				padding-bottom: 10px;
			}
		}
	}
	th {
		@include wide {
			padding-top: 2px;
			padding-right: 40px;
		}
	}
	th,
	td {
		font-size: 1.6rem;
		line-height: 1.5;
		vertical-align: top;
		letter-spacing: .02em;
	}
	tr:not(:last-child) {
		@include wide {
			th,
			td {
				padding-bottom: 4px;
			}
		}
	}
}
.careers-division-spec__table__en-text {
	font-family: $font_barlow;
}
.careers-division-spec__image {
	margin-top: 15px;

	@include wide {
		margin-top: 30px;
	}
}
.careers-division-spec__text {
	font-size: 1.6rem;
	font-weight: 400;
	line-height: 1.75;
	margin-top: 15px;

	@include wide {
		font-size: 1.8rem;
	}
}
.careers-division-spec__button {
	margin-top: 25px;

	@include wide {
		margin-top: 30px;
	}
	.basic-button {
		width: 206px;
		height: 48px;

		@include sp {
			margin-right: auto;
			margin-left: auto;
		}
	}
}

// business

.careers-division-business {
	background: $white;
	.secondary-panel__text,
	.secondary-panel__textbox {
		@include wide {
			margin-bottom: 0;
		}
	}
	.careers-division-intro +& {
		padding-top: 77px;

		@include wide {
			padding-top: 127px;
		}
	}
}
.careers-division-business__inner {
	padding-top: 55px;
	padding-bottom: 68px;
	background: $bg_gray;

	@include wide {
		padding-top: 90px;
		padding-bottom: 100px;
	}
	.section-inner {
		@include sp {
			padding-left: 0;
			padding-right:0;
		}
		@include wide {
			max-width: 100%;
		}
	}
}

// interview

.careers-division-interview {
	padding-top: 55px;
	padding-bottom: 40px;
	background: $white;

	@include wide {
		padding-top: 90px;
	}
}

// back button

.careers-division-back {
	padding-top: 107px;
	padding-bottom: 80px;
	background: $white;

	@include wide {
		padding-top: 180px;
		padding-bottom: 80px;
	}
	.basic-button {
		margin-right: auto;
		margin-left: auto;
	}
}

// organization_brand-enhancementのみ

.brand-enhancement .careers-division-organization__explanation {
	margin-top: 30px;
    display: block;
	width: 100%;

	&::before {
		background-color: #100f18;
		content: "";
		height: 75px;
		left: 50%;
		position: absolute;
		top: -30px;
		transform: translateX(-50%);
		width: 2px;

		@include wide {
			height: 88px;
			top: -30px;
		}
		@include pc {
			height: 80px;
			top: -30px;
			width: 2.5px;
		}
	}
	&::after {
		background-color: #100f18;
		content: "";
		height: 8px;
		left: 50%;
		position: absolute;
		top: 36px;
		transform: translateX(-50%) rotate(45deg);
		width: 8px;

		@include wide {
			top: 49px;
		}
		@include pc {
			top: 44px;
			height: 11px;
			width: 11px;
		}
	}

	@include pc {
		margin: 30px auto 0;
		width: calc(75% - 8px);
	}


}
.brand-enhancement .careers-division-organization__explanation__title {
	font-size: 2rem;
    font-weight: bold;
	margin-bottom: 10px;

	@include wide {
		font-size: 2.8rem;
		margin-bottom: 10px;
	}
}
.brand-enhancement .careers-division-organization__explanation__link {
	padding: 19px 20px 20px;

	@include wide {
		padding: 19px 26px 17px 24px;
	}
}
.brand-enhancement .careers-division-organization__explanation__heading {
	margin-bottom: 13px;

	@include wide {
		margin-bottom: 25px;
	}
}
.brand-enhancement .careers-division-organization__explanation__text {
	padding-left: 0;
	text-indent: 0;
}
.brand-enhancement .careers-division-organization__category__block {
	width: calc((100% - 15px) / 2);
	min-height: 110px;
	margin-bottom: 15px;
	padding: 20px 15px;
	color: $white;
	background: #000;

	@include wide {
		padding: 26px 30px 22px;
	}
	@include pc {
		width: calc((100% - 96px) / 4);
		margin-bottom: 0;
		margin-right: 32px;
	}
	&:nth-child(odd) {
		margin-right: 15px;

		@include pc {
			margin-right: 32px;
		}
	}
	&:first-of-type {
		order: 1;
		@include pc {
			order: 1;
		}
	}
	&:nth-of-type(2) {
		width: 100%;
		margin-right: 0;
		margin-bottom: 0;
		order: 3;
		@include pc {
			margin-right: 32px;
			width: calc((100% - 96px) / 4);
			order: 2;
		}
	}
	&:last-of-type {
		order: 2;
		margin-right: 0;

		@include wide {
			order: 2;
		}
		@include pc {
			order: 3;
		}
	}
	&--current {
		background: $red;
	}
}
.careers-division-pdf {
	background-color: #fff;
	padding: 60px 0 50px;
	@include wide {
	padding: 100px 0 0;
	}
	&__inner {
		width: 90%;
		margin: 0 auto;
		@include wide {
			max-width: 1200px;
		}
	}
	&__title {
		font-weight: 700;
		font-size: 24px;
		line-height: 1.5;
		letter-spacing: 0.1em;
		margin-bottom: 60px;
		@include wide {
			font-size: 28px;
			margin-bottom: 48px;
		}
	}
	&__content {
		width: 100%;
		margin: 0 auto;
		@include wide {
			width: 90%;
			max-width: 790px;
		}
	}
}

.careers-division-organization {
	&__content {
		padding: 4rem 2rem;
		width: 90%;
		margin: 0 auto;
		background-color: $white;
		@include wide {
			padding: 5rem;
			width: 100%;
		}
	}
	&__content-text {
		font-size: 1.2rem;
		font-weight: 700;
		line-height: 1.5;
		letter-spacing: 0.024rem;
		text-indent: -1.2rem;
		padding-left: 1.2rem;
		@include wide {
			font-size: 1.6rem;
			text-indent: -1.6rem;
			padding-left: 1.6rem;
		}
		&+& {
			margin-top: 2rem;
		}
		span {
			display: block;
			font-size: 1.2rem;
			margin-top: 1rem;
			font-weight: 400;
			@include wide {
				font-size: 1.4rem;
			}
		}
	}
	&__content-box {
		margin-top: 4rem;
		padding-top: 4rem;
		border-top: .1rem solid #C8CDD6;
		@include wide {
			margin-top: 5rem;
			padding-top: 5rem;
		}
	}
	&__content-box-inner {
		&+& {
			margin-top: 2rem;
			@include wide {
				margin-top: 3rem;
			}
		}
	}
	&__content-box-title {
		margin-bottom: 0.5rem;
		@include wide {
			margin-bottom: 1rem;
		}
		a {
			font-size: 1.6rem;
			font-weight: 700;
			letter-spacing: 0.032rem;
			text-decoration-line: underline;
			@include wide {
				font-size: 2rem;
			}
			&:hover {
				@include wide {
					text-decoration-line: none;
				}
			}
		}
	}
	&__content-box-text {
		font-size: 1.3rem;
		font-weight: 400;
		line-height: 1.75;
		letter-spacing: 0.026rem;
		@include wide {
			font-size: 1.6rem;
		}
	}
}