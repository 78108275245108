@use "../base/summarize" as * ;
.privacypolicy__mb14 {
	margin-bottom: 14px;
}
.privacypolicy__mb19 {
	margin-bottom: 19px;
}
.privacypolicy__mb28 {
	margin-bottom: 28px;
}

@include wide {
	.privacypolicy__mb0-wide {
		margin-bottom: 0;
	}
	.privacypolicy__mb24-wide {
		margin-bottom: 24px;
	}
}
