/* base */
@use "base/reset";
@use "base/mixin";
@use "base/var";

/* Library */
@use "lib/slick";

/* common module */
@use "module/common";
@use "module/header";
@use "module/footer";
@use "module/button";
@use "module/heading";
@use "module/accordion";
@use "module/slider";
@use "module/article";
@use "module/breadcrumbs";
@use "module/under-mv";
@use "module/simple-section";
@use "module/anchor-menu";
@use "module/message-panel";
@use "module/interview-module";
@use "module/panel";

/* pages */
@use "pages/top";
@use "pages/company";
@use "pages/company-enza";
@use "pages/company-business";
@use "pages/contact";
@use "pages/news";
@use "pages/privacypolicy";
@use "pages/news-single";
@use "pages/error";
@use "pages/careers";
@use "pages/careers-interviews-detail";
@use "pages/careers-faq";
@use "pages/careers-office";
@use "pages/careers-office-data";
@use "pages/careers-office-workstyle";
@use "pages/careers-division-detail";
@use "pages/careers-division";
@use "pages/careers-message";
@use "pages/history";


