@use "var" as * ;
@mixin wide {
	@media (min-width: (600px)) {
		@content;
	}
}
@mixin pc {
	@media (min-width: (1025px)) {
		@content;
	}
}
@mixin mobile {
	@media (max-width: (1024px)) {
		@content;
	}
}
@mixin tab {
	@media (min-width: (600px)) and (max-width: (1024px)) {
		@content;
	}
}
@mixin sp {
	@media (max-width: (599px)) {
		@content;
	}
}
@mixin sp-min {
	@media (max-width: (374px)) {
		@content;
	}
}
@mixin horizontal {
	@media all and (orientation:landscape) {
		@content;
	}
}
@mixin vertical {
	@media all and (orientation:portrait) {
		@content;
	}
}

@mixin carrer-mv {
	@media (min-width: (600px)) and (max-width: (1180px)) {
		@content;
	}
}


@mixin objectFit($fit) {
	width: 100%;
	height: 100%;
	@if ($fit == "cover") {
		font-family: "object-fit: cover;";

		object-fit: cover;
	}
}
