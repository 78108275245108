@use "../base/summarize" as * ;
//ここからcontainer---------------------------------

.company-container__inner {
	max-width: 600px;
	padding-top: 60px;
	padding-bottom: 80px;

	@include pc {
		display: flex;
		justify-content: space-between;
		max-width: 1296px;
		padding-top: 124px;
		padding-bottom: 124px;
	}
}
.company-container__box {
	width: 100%;
	padding-top: 44px;
	padding-right: 20px;
	padding-bottom: 44px;
	padding-left: 20px;
	background: #fff;
	box-shadow: 20px 20px 40px rgba(16, 15, 24, .1);

	@include pc {
		max-width: 576px;
		padding-top: 76px;
		padding-right: 48px;
		padding-bottom: 74px;
		padding-left: 48px;
	}
}
.company-container__box-1 {
	margin-bottom: 20px;

	@include pc {
		margin-right: 48px;
		margin-bottom: 0;
	}
}
.company-container__heading {
	margin-bottom: 20px;

	@include pc {
		margin-bottom: 42px;
	}
}
.company-container__heading-1 {
	@include pc {
		padding-left: 12px;
	}
}
.company-container__heading-2 {
	@include pc {
		padding-left: 2px;
	}
}
.company-container__text {
	font-size: 1.6rem;
	font-weight: bold;
	line-height: 2;
	text-align: center;

	@include pc {
		font-size: 2rem;
	}
}

//ここからCEOメッセージ---------------------------------
.company-message {
	background: #fff;
}
.company-message__inner {
	padding-top: 60px;
	padding-bottom: 106px;

	@include wide {
		padding: 100px 0 180px;
		padding-top: 100px;
		padding-bottom: 188px;
	}
	@include tab {
		padding-right: 48px;
		padding-left: 48px;
	}
}
.company-message__heading {
	margin-bottom: 24px;
	text-align: left;

	@include wide {
		margin-bottom: 46px;
		margin-left: 104px;
	}
}
.company-message__box {
	@include wide {
		display: flex;
		flex-direction: row-reverse;
		justify-content: space-between;
	}
	@include tab {
		display: flex;
		flex-direction: column;
		justify-content: start;
	}
}
.company-message__image {
	max-width: 233px;
	margin-right: auto;
	margin-bottom: 52px;
	margin-left: auto;

	@include wide {
		max-width: 312px;
		margin-right: 104px;
		margin-bottom: 0;
		margin-left: 0;
	}
	@include tab {
		margin-right: auto;
		margin-bottom: 52px;
		margin-left: auto;
	}
}
.company-message__textarea {
	@include wide {
		margin-right: 102px;
		margin-left: 104px;
	}
	@include tab {
		margin-right: 56px;
		margin-left: 56px;
	}
}
.company-message__text {
	font-size: 1.8rem;
	line-height: 1.75;
	margin-bottom: 34px;

	@include wide {
		line-height: 2;
		margin-bottom: 36px;
	}
}
.company-message__name {
	font-size: 1.8rem;
	line-height: 1;
	display: flex;
	flex-direction: column;
	padding-top: 16px;

	@include wide {
		padding-top: 8px;
	}
}
.company-message__name-sub {
	font-size: 2.4rem;
	padding-top: 18px;

	@include wide {
		padding-top: 19px;
	}
}

//ここから会社概要---------------------------------
.company-explanation {
	background: #000;
}
.company-explanation__inner {
	padding-top: 60px;
	padding-bottom: 88px;

	@include wide {
		padding-top: 100px;
		padding-bottom: 116px;
	}
}
.company-explanation__heading {
	margin-bottom: 33px;
	text-align: left;
	color: #fff;

	@include wide {
		margin-bottom: 49px;
		margin-left: 56px;
	}
}
.company-explanation__box {
	@include wide {
		margin: 0 56px;
	}
}
.company-explanation__textarea {
	font-size: 1.8rem;
	padding: 17px 10px;
	color: #fff;
	border-bottom: 1px solid #32313d;

	@include wide {
		display: flex;
		padding: 20px 30px;
	}
	@include tab {
		display: block;
	}
}
.company-explanation__textarea:first-of-type {
	border-top: 1px solid #32313d;
}
.company-explanation__textbox {
	display: flex;
	flex-direction: column;
}
.company-explanation__flex {
	display: flex;
	flex-direction: row;
}
.company-explanation__map-1 {
	font-weight: bold;
	position: relative;
	display: flex;
	align-items: center;
	margin-left: 19px;
	letter-spacing: -.05em;

	@include wide {
		display: none;
	}
}
.company-explanation__map-1::before {
	display: block;
	box-sizing: border-box;
	width: 14px;
	height: 20px;
	margin-right: 5px;
	content: "";
	background-image: url("/assets/img/common/icon-map.svg");
	background-position: center;
	background-size: cover;

	@include wide {
		display: none;
	}
}
.company-explanation__map-2 {
	display: none;

	@include wide {
		font-weight: bold;
		position: relative;
		display: flex;
		align-items: center;
		margin-left: 47px;
		letter-spacing: -.05em;
	}
}
.company-explanation__map-2::before {
	@include wide {
		display: block;
		box-sizing: border-box;
		width: 14px;
		height: 20px;
		margin-right: 5px;
		content: "";
		background-image: url("/assets/img/common/icon-map.svg");
		background-position: center;
		background-size: cover;
	}
}
.company-explanation__title {
	font-weight: bold;
	color: #fff;

	@include wide {
		min-width: 178px;
	}
}
.company-explanation__text-sub {
	display: inline-block;
	width: 155px;

	@include wide {
		width: 208px;
	}
}
.company-explanation__link {
	position: relative;
	display: flex;
	padding-left: 32px;
	text-decoration: underline;

	@include wide {
		line-height: 2;
		padding-left: 32px;
		&:hover {
			text-decoration: none;
		}
	}
}
.company-explanation__link::before {
	position: absolute;
	top: 6px;
	left: 1px;
	display: block;
	box-sizing: border-box;
	width: 18px;
	height: 18px;
	content: "";
	background-image: url("/assets/img/common/icon-pdf.svg");
	background-position: center;
	background-size: cover;

	@include wide {
		top: 10px;
	}
}


//ここから沿革---------------------------------
.company-history {
	background: #272222;
}
.company-history__inner {
	padding-top: 60px;
	padding-bottom: 80px;

	@include wide {
		padding-top: 82px;
		padding-bottom: 116px;
	}
}
.company-history__heading {
	margin-bottom: 33px;
	text-align: left;
	color: #fff;

	@include wide {
		margin-bottom: 42px;
		margin-left: 56px;
	}
}
.company-history__box {
	@include wide {
		margin: 0 56px;
	}
}
.company-history__textarea {
	font-size: 1.8rem;
	padding: 17px 10px;
	color: #fff;
	border-bottom: 1px solid #32313d;

	@include wide {
		display: flex;
		padding: 20px 30px;
	}
	@include tab {
		display: block;
	}
}
.company-history__textarea:first-of-type {
	border-top: 1px solid #32313d;
}
.company-history__title {
	font-weight: bold;

	@include wide {
		min-width: 208px;
	}
}
.company-history__title-sub-1 {
	margin-right: 38px;

	@include wide {
		margin-right: 0;
	}
}
.company-history__title-sub-2 {
	@include wide {
		margin-left: 45px;
	}
}

//ここからcompany-text---------------------------------
.company-text {
	background: #fff;
}
.company-text__inner {
	padding-top: 70px;
	padding-bottom: 60px;

	@include wide {
		padding-top: 68px;
		padding-bottom: 140px;
	}
}
.company-text__textarea {
	font-size: 1.8rem;
	// margin-bottom: 120px;

	@include wide {
		margin-right: 56px;
		// margin-bottom: 100px;
		margin-left: 56px;
	}
}
.company-text__text:first-of-type {
	line-height: 1.75;
	margin-bottom: 40px;

	@include wide {
		line-height: 2;
		margin-bottom: 40px;
	}
}
.company-text-red {
	font-weight: bold;
}
