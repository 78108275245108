@use "../base/summarize" as * ;
// mv

.careers-interviews-detail-mv {
	height: 375px;

	@include wide {
		height: 600px;
	}
	.under-mv__inner {
		@include sp {
			align-items: flex-end;
			padding-bottom: 120px;
		}
		@include wide {
			padding-bottom: 100px;
		}
	}
	.under-mv__heading {
		font-size: 2rem;
		line-height: 1.5;

		@include sp {
			padding-left: 30px;
		}
		@include wide {
			font-size: 4rem;
		}
	}
	.under-mv__image {
		img {
			font-family: "object-fit: cover;object-position: 65% 0;";

			object-fit: cover;
			object-position: 65% 0;
		}
	}
}
.careers-interviews-detail-mv__text {
	font-family: $font_barlow_c;
	font-size: 1.4rem;
	font-weight: 600;
	display: block;
	margin-top: 18px;
	letter-spacing: .1em;

	@include wide {
		font-size: 2.4rem;
		margin-top: 35px;
		letter-spacing: .05em;
	}
}


// プロフィール

.careers-interviews-detail-profile--narrow {
	.section-inner {
		@include wide {
			max-width: 1192px;
		}
	}
}
.careers-interviews-detail-profile__inner {
	position: relative;
	z-index: 2;
	margin-top: -80px;
	padding: 30px 20px 40px;
	background: #fff;
	box-shadow: 10px 10px 40px rgba(16, 15, 24, .1);

	@include wide {
		max-width: 784px;
		margin-top: -140px;
		margin-right: auto;
		margin-left: auto;
		padding: 40px 56px;
	}
	.careers-interviews-detail-profile--narrow & {
		@include wide {
			max-width: 500px;
			margin-left: 0;
		}
	}
}
.careers-interviews-detail-profile__name {
	font-size: 2.4rem;

	@include wide {
		letter-spacing: .1em;
	}
	span {
		font-size: 1.6rem;
		font-weight: normal;
		display: block;

		@include wide {
			letter-spacing: .02em;
		}
	}
}
.careers-interviews-detail-profile__text {
	margin-top: 15px;
}


// 共通パーツ

.careers-interviews-detail-inner {
	@include wide {
		max-width: calc(992px + #{$side_padding_pc} * 2);
	}
}
.careers-interviews-detail-heading {
	font-size: 2.4rem;
	line-height: 1.5;
	margin-bottom: 40px;
	letter-spacing: .1em;

	@include wide {
		font-size: 2.8rem;
	}
}
.careers-interviews-detail-sub-heading {
	font-size: 1.9rem;
	line-height: 1.5;
	margin-bottom: 15px;
	letter-spacing: .1em;

	@include wide {
		font-size: 2.0rem;
	}
	&:not(:first-child) {
		margin-top: 40px;

		@include wide {
			margin-top: 50px;
		}
	}
}
.careers-interviews-detail-common-text {
	font-size: 1.8rem;
	line-height: 1.75;

	@include wide {
		line-height: 2;
	}
	& +& {
		margin-top: 35px;
	}
	a {
		color: $red;
		font-weight: bold;
		text-decoration: none;

		text-underline-position: from-font;

		&:hover {
			@include wide {
				text-decoration: underline;
			}
		}
	}
	&--mtb{
		margin: 42px 0;
		@include wide {
			margin: 64px 0;
		}
	}
}
.careers-interviews-detail-textimage {
	@include wide {
		display: flex;
		max-width: 1440px;
		margin-right: auto;
		margin-left: auto;
	}
	&:not(.careers-interviews-detail-textimage--reverse) {
		@include wide {
			flex-direction: row-reverse;
			justify-content: flex-end;
		}
	}
	&--narrow {
		@include wide {
			max-width: 1080px;
		}
	}
}
.careers-interviews-detail-textimage__image {
	@include sp {
		margin-top: 42px;
	}
	@include wide {
		flex: none;
		width: 39.884%;
		margin-right: 10%;
		margin-left: -$side-padding-pc;
	}
	.careers-interviews-detail-textimage--reverse & {
		@include wide {
			margin-right: -$side-padding-pc;
			margin-left: 10%;
		}
	}
	.careers-interviews-detail-textimage--narrow & {
		@media (min-width: (1440px)) {
			margin-right: -224px;
		}
		// @media (min-width: (1200px)) {
		// 	margin-right: -$side-padding-pc;
		// }
		// @include wide {
		// 	width: auto;
		// 	margin-left: 0;
		// 	margin-right: 0;
		// }
		// @media (min-width: (1440px)) {
		// 	margin-right: -$side-padding-pc;
		// }
	}
}
.careers-interviews-detail-textimage__outline {
	@include wide {
		width: calc(50% + 80px);
		max-width: 680px;
	}
	.careers-interviews-detail-textimage--narrow & {
		@include wide {
			width: 100%;
			max-width: 100%;
			flex: 1;
		}
	}
}
.careers-interviews-detail-textimage__caption{
	display: block;
	font-size: 14px;
	text-align: right;
	line-height: 175%;
	margin-top: 3px;
}
.careers-interviews-detail-image {
	@include sp {
		margin-top: 50px;
		margin-right: -$side-padding-sp;
		margin-left: -$side-padding-sp;
	}
	@include wide {
		margin-top: 65px;
	}
	&:not(:last-child) {
		margin-bottom: 30px;

		@include wide {
			margin-bottom: 45px;
		}
	}
	&--last {
		@include wide {
			margin-top: 100px;
		}
	}
}
.careers-interviews-detail-numbered-list {
  counter-reset: item;
  list-style-type: none;
}
.careers-interviews-detail-numbered-list__text {
  text-indent: -1.3em;
  padding-left: 1.3em;
	&+& {
		margin-top: 5px;
	}
	&:before {
		counter-increment: item;
		content: counter(item)'.';
		padding-right: .5em;
	}
}


// section - intro

.careers-interviews-detail-intro {
	padding-top: 72px;
	padding-bottom: 74px;

	@include wide {
		padding-top: 148px;
		padding-bottom: 100px;
	}
}

// section - explain

.careers-interviews-detail-explain {
	padding-top: 72px;
	padding-bottom: 80px;
	background: $white;

	@include wide {
		padding-top: 120px;
		padding-bottom: 120px;
	}
}

// section - explain02

.careers-interviews-detail-explain02 {
	padding-top: 72px;
	padding-bottom: 80px;
	background: $white;

	@include wide {
		padding-top: 120px;
		padding-bottom: 120px;
	}

	.section-inner {
		@include wide {
			max-width: 1088px;
		}
	}
	.careers-interviews-detail-textimage__outline{
		@include wide {
			max-width: 520px;
		}
	}
	.careers-interviews-detail-textimage__image{
		@include wide {
			width: 39.884%;
			margin-left: 8%;
			margin-right: 0;
		}
		&--left{
			margin: 0 0 42px 0;
			@include wide {
				margin: 0 8% 0 0;
			}
		}
	}
	.careers-interviews-detail-textimage__image-shadow{
		box-shadow: 10px 10px 40px rgba(16, 15, 24, 0.1);
	}
}

// section - point

.careers-interviews-detail-point {
	padding-top: 80px;
	padding-bottom: 74px;

	@include wide {
		padding-top: 128px;
		padding-bottom: 130px;
	}
}

// section - advice

.careers-interviews-detail-advice {
	padding-top: 72px;
	background: $white;

	@include wide {
		padding-top: 110px;
	}
	&--bg {
		background: none;
		padding-bottom: 72px;
		@include wide {
			padding-bottom: 110px;
		}
	}
	&--pb {
		padding-bottom: 80px;
		@include wide {
			padding-bottom: 120px;
		}
	}
}

// section - panel

.careers-interviews-detail-panel {
	padding-top: 90px;
	padding-bottom: 96px;
	background: $white;

	@include wide {
		padding-top: 110px;
		padding-bottom: 172px;
	}
}

// section - others

.careers-interviews-detail-others {
	background: $white;
}
.careers-interviews-detail-others__heading {
	font-size: 2rem;
	margin-bottom: 30px;
	letter-spacing: .05em;

	@include wide {
		font-size: 2.4rem;
		margin-bottom: 35px;
	}
}
.careers-interviews-detail-others__slider {
	position: relative;
	padding-bottom: 78px;
	&--pb0{
		padding-bottom: 0px;
	}
	@include wide {
		padding-bottom: 70px;
		max-width: 1342px;
		&--pb0{
			padding-bottom: 0px;
		}
	}
	.slick-slider__utility {
		position: relative;
		z-index: 2;

		@include wide {
			margin-top: 70px;
		}
		.slick-dots {
			li {
				&:not(.slick-active) {
					button:not(:hover) {
						&:after {
							background: $dark_gray;
						}
					}
				}
			}
		}
	}
}
.careers-interviews-detail-others__slider__bg{
	position: absolute;
	z-index: 1;
	right: 0;
	bottom: 0;
	left: 50%;
	display: block;
	width: 100%;
	height: calc(100% - 53vw);
	transform: translateX(-50%);
	background: $black;

	@include wide {
		width: calc(100vw + 5px);
		height: calc(100% - 276px);
		margin-left: -1px;
	};
}
.careers-interviews-detail-others__slider__content {
	position: relative;
	z-index: 2;
	@include sp {
		max-width: 78.4vw;
		margin-right: auto;
		margin-left: auto;
	}
	.slick-list {
		overflow: visible;
	}
}
.careers-interviews-detail-others__panel {
	width: 100%;
}
.careers-interviews-detail-others__panel__inner {
	display: block;
	margin: 0 15px;
	position: relative;
	@include wide {
		margin-right: 24px;
		margin-left: 24px;
	}
	&:before {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		background: #fff;
		position: absolute;
		left: 0;
		top: 0;
		opacity: 0.8;
		z-index: 3;
		pointer-events: none;
		transition: opacity .3s;
		opacity: 0;
	}
	.slick-slide:not(.slick-active) & {
		&:before {
			opacity: .8;
		}
	}
}
.careers-interviews-detail-others__panel__image {
	box-shadow: $box_shadow;

	@include wide {
		transition: box-shadow .3s;
		a:hover & {
			box-shadow: $box_shadow_hover;
		}
	}
	picture {
		overflow: hidden;
	}
	img {
		@include wide {
			transition: transform .3s;
		}
	}
	a:hover & {
		@include wide {
			img {
				transform: scale(1.03,1.03);
			}
		}
	}
}
.careers-interviews-detail-others__slider--no-bg {
	max-width: 1296px;
}
.careers-interviews-detail-others__slider__content--no-bg {
	.careers-interviews-detail-others__panel__image {
		box-shadow: none;
	}
	.careers-interviews-detail-others__slider__item {
		padding: 0 15px;
	}
	.careers-interviews-detail-others__panel {
		box-shadow: $box_shadow;

		@include wide {
			transition: box-shadow .3s;
			a:hover & {
				box-shadow: $box_shadow_hover;
			}
		}
		a {
			margin: 0;
		}
		a:hover & {
			@include wide {
				img {
					transform: scale(1,1);
				}
			}
		}
	}
}
.careers-interviews-detail-others__panel__outline {
	position: relative;
	display: flex;
	flex-direction: column;
	padding: 20px;
	background: $white;

	@include wide {
		padding: 20px 28px 40px 28px;
	}
	&:after {
		position: absolute;
		right: 16px;
		bottom: 16px;
		display: block;
		width: 20px;
		height: 8px;
		content: "";
		transform-origin: center right;
		background: url("/assets/img/common/arrow-next-red.svg");
		background-size: 100% 100%;

		@include wide {
			transition: transform .3s;
			bottom: 24px;
			@at-root a:hover & {
				transform: scaleX(.7);
			}
		}
	}
}
.careers-interviews-detail-others__sub-heading {
	font-size: 2rem;
	font-weight: 600;
	line-height: 1.5;
	margin-bottom: 12px;

	@include wide {
		font-size: 2.4rem;
		margin-bottom: 20px;
	}
}
.careers-interviews-detail-others__name {
	font-size: 1.2rem;
	margin-top: auto;
	margin-bottom: 0;

	@include wide {
		font-size: 1.4rem;
		line-height: 1.5;
	}
	em {
		font-size: 1.8rem;
		font-weight: 600;
		font-style: normal;
		display: block;
		margin-top: 2px;

		@include wide {
			font-size: 2rem;
		}
	}
}


// back button

.careers-interviews-detail-back {
	padding-top: 70px;
	padding-bottom: 120px;
	background: $white;

	@include wide {
		padding-top: 116px;
		padding-bottom: 80px;
	}
	.basic-button {
		margin-right: auto;
		margin-left: auto;
	}
}


// ページごとの調整

.careers-interviews-detail-07 {
	.under-mv__heading {
		@include sp {
			font-size: 1.8rem;
		}
	}
}

.careers-interviews-detail-extra-01{
	.careers-interviews-detail-advice{
		padding: 80px 0;
		background: none;
		@include wide {
			padding: 120px 0;
		}
	}
}


.section-inner--data {
	> div:nth-of-type(1) {
		margin-bottom: 40px;
	}
}
@include wide {
	.section-inner--data {
		display: flex;
		> div:nth-of-type(1) {
			margin-bottom: 0;
			width: calc(33.33333%);
			.primary-panel {
				width: 100%;
			}
		}
		> div:nth-of-type(2) {
			width: calc(66.66666%);
			.primary-panel {
				width: 50%;
				margin-right: 0;
			}
		}
	}
}

.careers-interviews-detail {
	&__link {
		background: $white;
	}
	&__link-inner {
		padding-right: 20px;
		padding-left: 20px;
		@include wide {
			margin: 0 auto;
			max-width: calc(992px + #{$side_padding_pc} * 2);
		}
	}
	&__link-title {
		font-size: 2.4rem;
		line-height: 1.5;
		margin-bottom: 60px;
		letter-spacing: .05em;
		padding-top: 80px;
		border-top: 1px solid #C8CDD6;
		@include wide {
			padding-top: 120px;
		}
	}
	&__link-item {
		margin-bottom: 30px;
		&:last-of-type {
			margin-bottom: 0;
		}
	}
	&__link-item a {
		@include wide {
			display: flex;
		}
		&:hover p {
			text-decoration: underline;
		}
	}
	&__link-item time {
		display: block;
		position: relative;
		white-space: nowrap;
		word-break: keep-all;
		font-size: 1.6rem;
		font-weight: 400;
		letter-spacing: 0.036rem;
		margin-right: 2rem;
		padding-left: 1.5rem;
		margin-bottom: 5px;
		@include wide {
			font-size: 1.8rem;
			margin-bottom: 0;
		}
		&::before {
			position: absolute;
			content: '';
			top: 13px;
			left: 0;
			transform: translateX(-50%) rotate(45deg);
			width: 7px;
			height: 7px;
			border: 1px solid $red;
			border-bottom: none;
			border-left: none;
		}
	}
}