@use "../base/summarize" as * ;
.anchor-menu {
	display: flex;
	flex-wrap: wrap;
}
.anchor-menu__item {
	display: flex;

	@include mobile {
		width: calc((100% - 11px) / 2);
		margin-right: 11px;
		&:nth-child(even) {
			margin-right: 0;
		}
		&:nth-of-type(n+3) {
			margin-top: 11px;
		}
	}
	@include pc {
		width: 22%;
		&:not(:nth-child(4n)) {
			margin-right: 4%;
		}
	}
	&:after {
		display: block;
		height: 60px;
		content: "";
	}
	a {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		padding: 16px 0 24px;
		text-align: center;
		border: 1px solid $warm_gray;

		@include wide {
			padding-top: 6px;
			padding-right: 20px;
			padding-bottom: 6px;
			transition: .4s border;
		}
		&:after {
			position: absolute;
			display: block;
			width: 5px;
			height: 5px;
			content: "";
			transform: rotate(45deg);
			border: solid $red;
			border-width: 0 1px 1px 0;

			@include sp {
				bottom: 10px;
				left: 50%;
			}
			@include wide {
				top: 50%;
				right: 16px;
				width: 7px;
				height: 7px;
				margin-top: -2px;
				transition: margin .3s;
			}
		}
		&:hover {
			@include wide {
				border-color: $red;
				&:after {
					margin-top: 0px;
				}
			}
		}
	}
}
.anchor-menu__text {
	font-size: 1.3rem;
	font-weight: 600;
	line-height: 1.46;

	@include wide {
		font-size: 1.6rem;
	}
}
