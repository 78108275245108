@use "../base/summarize" as * ;
// 箱--------------------------------
.simple-section {
	padding-bottom: 80px;

	@include wide {
		padding-bottom: 144px;
	}
}
.simple-section__inner {
	@include wide {
		max-width: 1088px;
	}
}
.simple-section__block-1 {
	margin-bottom: 24px;

	@include wide {
		margin-bottom: 42px;
	}
}
.simple-section__block-2 {
	margin-bottom: 121px;

	@include wide {
		margin-bottom: 54px;
	}
}
.simple-section__block {
	margin-bottom: 60px;

	@include wide {
		margin-bottom: 45px;
	}
}
.simple-section__block:last-of-type {
	margin-bottom: 0;
}
.simple-section__box {
	margin-bottom: 60px;

	@include wide {
		margin-bottom: 45px;
	}
}
.simple-section__box:last-of-type {
	margin-bottom: 0;
}

// タイトル--------------------------------
.simple-section__heading-top {
	margin-bottom: 38px;

	@include wide {
		margin-bottom: 55px;
		padding-top: 44px;
		letter-spacing: .1em;
	}
}
.simple-section__heading {
	font-size: 2rem;
	line-height: 1.5;
	margin-bottom: 38px;
	padding-left: 2px;
	letter-spacing: .05em;

	@include wide {
		font-size: 2.4rem;
		margin-bottom: 45px;
	}
}
.simple-section__title {
	font-size: 1.8rem;
	line-height: 1.5;
	margin-bottom: 17px;
	letter-spacing: .1em;

	@include wide {
		font-size: 2rem;
		margin-bottom: 19px;
		letter-spacing: .05em;
	}
}
.simple-section__ttl {
	font-size: 1.6rem;
	line-height: 1.5;
	margin-bottom: 13px;
	letter-spacing: .1em;

	@include wide {
		margin-bottom: 16px;
		letter-spacing: .05em;
	}
}

// テキスト--------------------------------
.simple-section__text {
	font-size: 1.6rem;
	line-height: 1.75;
	letter-spacing: .02em;
}
.simple-section__text + .simple-section__text {
	margin-top: 20px;
}
.simple-section__text +ul {
	padding-top: 26px;

	@include wide {
		margin-bottom: 51px;
	}
}
.simple-section__text +h4 {
	padding-top: 29px;
}
.simple-section__text-red {
	font-weight: bold;
	line-height: 1.5;
	letter-spacing: -.045em;
	color: $red;

	@include wide {
		letter-spacing: .02em;
	}
}
.simple-section__text-red +p {
	@include wide {
		margin-bottom: 55px;
	}
}
.simple-section__text-right {
	text-align: right;
}
.simple-section__text-annotation {
	font-size: 1.2rem;
	line-height: 1.75;
	padding-top: 9px;
	letter-spacing: 0;
}
.simple-section__text-record {
	display: flex;
	padding-top: 28px;
	&::before {
		margin-right: 14px;
		content: "-";
		transform: translate(-3px,-2px) scaleX(4);
		transform-origin: left;
	}
	&::after {
		margin-left: 14px;
		content: "-";
		transform: translate(-3px,-2px) scaleX(4);
	}
}

// ナビゲーション--------------------------------
.simple-section__gnav {
	margin-bottom: 24px;
	counter-reset: number kana lower-alpha;

	@include wide {
		margin-bottom: 28px;
	}
}
.simple-section__list {
	position: relative;
	margin-bottom: 16px;
	padding-left: 24px;

	@include wide {
		margin-bottom: 20px;
	}
}
.simple-section__list:last-of-type {
	margin-bottom: 0;
}
.simple-section__gnav-nav {
	counter-reset: number kana lower-alpha;
	.simple-section__list {
		margin-bottom: 0;
		padding-left: 0;
		&:before {
			content: none;
		}
	}
}

.simple-section__list--curcle {
	text-indent: -1.6rem;
	&::before {
		display: none;
	}
}

// リンク--------------------------------
.simple-section__link {
	position: relative;
	display: flex;
	margin-bottom: 10px;
	padding-left: 28px;
	text-decoration: underline;

	@include wide {
		margin-bottom: 0;
		&:hover {
			text-decoration: none;
		}
	}
}
.simple-section__link:last-of-type {
	margin-bottom: 0;
}
.simple-section__link::before {
	position: absolute;
	top: 6px;
	left: 1px;
	display: block;
	box-sizing: border-box;
	width: 18px;
	height: 18px;
	content: "";
	background-image: url("/assets/img/common/icon-pdf-black.svg");
	background-position: center;
	background-size: cover;
}
.simple-section__link +p {
	@include wide {
		margin-bottom: 63px;
	}
}
.simple-section__link-line {
	@include wide {
		&:hover {
			text-decoration: underline;
		}
	}
}
.simple-section__link-second {
	text-decoration: underline;
	&:hover {
		text-decoration: none;
	}
}

.policy-table {
	margin-top: 30px;
	font-size: 1.4rem;
	border: 1px solid $warm_gray;
	@include wide {
		font-size: 1.6rem;
	}
	&__head {
		border-bottom: 1px solid $warm_gray;
		width: 68.65%;
		font-weight: bold;
		padding: 10px;
		@include wide {
			padding: 10px 20px;
		}
		&:first-of-type {
			border-right: 1px solid $warm_gray;
			width: 31.35%;
		}
	}
	&__title {
		border-right: 1px solid $warm_gray;
		border-bottom: 1px solid $warm_gray;
		width: 31.35%;
		vertical-align: baseline;
		font-weight: bold;
		padding: 10px;
		@include wide {
			padding: 10px 20px;
		}
	}
	&__content {
		width: 68.65%;
		border-bottom: 1px solid $warm_gray;
	}
	&__item {
		border-bottom: 1px solid $warm_gray;
		padding: 10px;
		@include wide {
			padding: 10px 20px;
		}
		&:last-of-type {
			border-bottom: none;
		}
	}
}