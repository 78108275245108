@use "../base/summarize" as * ;
// メインのボタン

.basic-button {
	font-size: 1.6rem;
	font-weight: 600;
	line-height: 1.48;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	max-width: 264px;
	height: 60px;
	text-align: center;
	color: $white;
	border: 1px solid $red;
	border-radius: 999px;
	background: $red;

	@include wide {
		max-width: 368px;
		transition: .4s;
	}
	&:hover {
		@include wide {
			color: $red;
			background: $white;
			box-shadow: 10px 10px 20px rgba(16, 15, 24, .1);
		}
	}
	&--small {
		font-size: 1.4rem;
		width: 124px;
		height: 40px;
		&:hover {
			@include wide {
				box-shadow: none;
			}
		}
	}
	&--border {
		color: $red;
		background: none;
		&:hover {
			@include wide {
				border-color: $red;
				background: $white;
			}
		}
	}
	&--gray-border {
		color: $black;
		border-color: $warm_gray;
		background: none;
		&:hover {
			@include wide {
				border-color: $red;
			}
		}
	}
}
.basic-button__inner {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
}
.basic-button__icon {
	display: block;
	.basic-button--external & {
		position: relative;
		top: -6px;
		width: 8px;
		height: 8px;
		margin-right: -10px;
		margin-left: 3px;
		background: url("/assets/img/common/icon-external-white.svg") center center;
		background-size: contain;
	}
	.basic-button:hover & {
		@include wide {
			background-image: url("/assets/img/common/icon-external-red.svg");
		}
	}
	.basic-button--gray-border & {
		background-image: url("/assets/img/common/icon-external-black.svg");
	}
	.basic-button--gray-border:hover & {
		@include wide {
			background-image: url("/assets/img/common/icon-external-red.svg");
		}
	}
	.basic-button--border.basic-button--external & {
		background-image: url("/assets/img/common/icon-external-red.svg");
	}
}

// テキストリンク

// インライン
.inline-link {
	position: relative;
	color: $red;
	&:after {
		position: absolute;
		bottom: 2px;
		left: 0;
		display: block;
		width: 100%;
		height: 1px;
		content: "";
		transition: .4s opacity;
		opacity: 0;
		background: $red;
	}
	a:hover,
	&:hover {
		&:after {
			@include wide {
				opacity: 1;
			}
		}
	}
}

// 矢印つき
.link-text {
	font-size: 1.2rem;
	display: inline-flex;
	align-items: center;
	color: $red;
	&:after {
		display: block;
		width: 16px;
		height: 6px;
		margin-left: 10px;
		content: "";
		transition: .4s transform;
		transform-origin: center right;
		background: url("/assets/img/common/arrow-next-red.svg");
		background-size: 100% 100%;
	}
	a:hover &,
	&:hover {
		&:after {
			transform: scaleX(.8);
		}
	}
}
.link-text__inner {
	position: relative;
	&:after {
		position: absolute;
		bottom: 2px;
		left: 0;
		display: block;
		width: 100%;
		height: 1px;
		content: "";
		transition: .4s opacity;
		opacity: 0;
		background: $red;
	}
	a:hover &,
	.link-text:hover & {
		&:after {
			@include wide {
				opacity: 1;
			}
		}
	}
}


//サイド固定ボタン

.side-fix-button {
	position: fixed;
	z-index: 100;
	top: 380px;
	right: 0;
	display: block;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 80px;
	height: 80px;
	background: $red;
	box-shadow: 20px 20px 30px rgba(16, 15, 24, .3);

	@include wide {
		font-size: 1.6rem;
		top: 338px;
		width: 90px;
		height: 90px;
		transition: opacity .2s;
		&:hover {
			opacity: .6;
		}
	}
}
.side-fix-button__text {
	font-size: 1.4rem;
	font-weight: bold;
	line-height: 1.7;
	color: #fff;

	@include wide {
		font-size: 1.6rem;
	}
}
