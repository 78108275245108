@use "../base/summarize" as * ;
html {
	font-size: 62.5%;
}
body {
	font-family: $font_base;
	font-size: 1.4rem;
	line-height: 1.75;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 100vh;
	letter-spacing: .02em;
	color: $black;
	background: $bg_gray;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
img {
	width: 100%;
	height: auto;
}
a {
	color: inherit;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	img[src$=".svg"] {
		width: 100%;
	}
}
main {
	overflow: hidden;
	min-height: 0%; // body flex hack for IE
	margin-top: $header_height_sp;
	margin-bottom: auto;

	@include pc {
		margin-top: $header_height_pc;
	}
}
.sp_only {
	@include wide {
		display: none;
	}
}
.tab_only {
	@include sp {
		display: none;
	}
	@include pc {
		display: none;
	}
}
.wide_only {
	@include sp {
		display: none;
	}
}
.pc_only {
	@include sp {
		display: none;
	}
	@include tab {
		display: none;
	}
}
.hidden-text {
	position: absolute;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	width: 1px;
	height: 1px;
	padding: 0;
	white-space: nowrap;
	border: 0;
}
.section-inner {
	padding-right: $side_padding_sp;
	padding-left: $side_padding_sp;

	@include wide {
		width: 100%;
		max-width: calc(1200px + #{$side_padding_pc} * 2);
		margin-right: auto;
		margin-left: auto;
		padding-right: $side_padding_pc;
		padding-left: $side_padding_pc;
	}
}
[data-anchor] {
	cursor: pointer;
}
.sp_br {
    display: inline !important;
    @include wide {
        display: none !important;
    }
}
.pc_br {
    display: none !important;
    @include wide {
        display: inline !important;
    }
}

.floating-banner {
	display: none;
	position: fixed;
	right: 0;
	bottom: 40px;
	width: 110px;
	z-index: 99;
	@include wide {
		width: 180px;
	}
	a {
		display: block;
		box-shadow: 10px 30px 40px rgba(16, 15, 24, 0.2);
	}
}