@use "../base/summarize" as * ;
.message-panel {
	box-shadow: $box_shadow;

	@include pc {
		display: flex;
	}
}
.message-panel__image {
	@include pc {
		flex: none;
		width: 38.167%;
	}
	picture {
		@include wide {
			width: 100%;
			height: 100%;
			img {
				@include objectFit(cover);
			}
		}
	}
}
.message-panel__outline {
	padding: 30px 20px 44px 20px ;

	@include wide {
		flex: 1;
		padding: 50px 60px;
	}
}
.message-panel__heading {
	font-size: 1.8rem;
	margin-bottom: 15px;
	letter-spacing: .1em;

	@include wide {
		font-size: 2rem;
	}
}
.message-panel__text {
	font-size: 1.6rem;

	@include wide {
		min-height: 70px;
	}
	& +& {
		margin-top: 1em;
	}
}
.message-panel__buttons {
	margin-top: 30px;

	@include wide {
		margin-top: 35px;
	}
	@media (min-width: (1200px)) {
		display: flex;
	}
	& +.message-panel__buttons {
		margin-top: 9px;

		@include wide {
			margin-top: 12px;
		}
	}
}
.message-panel__buttons__block {
	@include wide {
		width: 100%;
	}
	@include pc {
		max-width: 306px;
	}
	&:not(:last-child) {
		margin-bottom: 9px;
		@media (min-width: (1200px)) {
			margin-right: 12px;
			margin-bottom: 0;
		}
	}
	.basic-button {
		font-size: 1.4rem;
		max-width: 274px;
		height: 48px;
		margin-right: auto;
		margin-left: auto;

		@include sp-min {
			font-size: 1.2rem;
		}
		@include wide {
			height: 40px;
		}
		@media (min-width: (1200px)) {
			width: 100%;
			max-width: 100%;
			margin-right: 0;
			margin-left: 0;
		}
		& +.basic-button {
			margin-top: 9px;
		}
	}
	.basic-button__text {
		font-weight: normal;
		line-height: 1.2;
		em {
			font-weight: 600;
			font-style: normal;
		}
	}
	&--wide {
		@include wide {
			max-width: 430px;
		}
		@include tab {
			margin-right: auto;
			margin-left: auto;
		}
		.basic-button {
			max-width: 100%;
		}
	}
}
