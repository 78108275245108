@use "../base/summarize" as * ;

// 大きいパネル

.primary-panel-list {
	@include wide {
		display: flex;
		flex-wrap: wrap;
	}
}
.primary-panel-list--slick,
.primary-panel-list--size {
	margin-left: auto;
    margin-right: auto;
    max-width: 78.4vw;
	.primary-panel {
		box-shadow: none;
		&:hover {
			box-shadow: none;
		}
	}
	.slick-list {
		overflow: visible;
	}
	.primary-panel__inner {
		margin: 0 15px;
		box-shadow: $box_shadow;
	}
}
.primary-panel {
	margin-bottom: 24px;
	box-shadow: $box_shadow;

	@include wide {
		margin-right: 48px;
		margin-bottom: 0;
		transition: box-shadow .3s;
		&:hover {
			cursor: pointer;
			box-shadow: $box_shadow_hover;
			.primary-panel__icon {
				transform: scaleX(.85);
			}
		}
	}
	@include tab {
		width: calc(50% - 24px);
	}
	@include pc {
		width: calc(calc(100% / 3) - 32px);
	}
	&:nth-of-type(n+3) {
		@include tab {
			margin-top: 48px;
		}
	}
	&:nth-of-type(n+4) {
		@include pc {
			margin-top: 48px;
		}
	}
	&:nth-of-type(2n) {
		@include tab {
			margin-right: 0;
		}
	}
	&:nth-of-type(3n) {
		@include pc {
			margin-right: 0;
		}
	}
	&:last-of-type {
		margin-bottom: 0;

		@include wide {
			margin-right: 0;
		}
	}
}
.primary-panel__inner {
	display: flex;
	flex-direction: column;
	height: 100%;
}
.primary-panel__image {
	overflow: hidden;
	img {
		@include wide {
			transition: transform .3s;
		}
	}
	a:hover & {
		@include wide {
			img {
				transform: scale(1.03,1.03);
			}
		}
	}
}
.primary-panel__textarea {
	position: relative;
	padding: 20px 20px 36px;
	background: $white;

	@include wide {
		min-height: 188px;
		padding-right: 28px;
		padding-bottom: 40px;
		padding-left: 28px;
	}

	flex-grow: 1;
}
.primary-panel__sub-title {
	font-size: 2rem;
	font-weight: bold;
	line-height: 1.5;
	letter-spacing: 0;

	@include wide {
		font-size: 2.4rem;
		letter-spacing: .05em;
	}
	&:not(:first-child) {
		margin-top: 4px;

		@include wide {
			margin-top: 8px;
		}
	}
	&--small {
		@include wide {
			font-size: 2.2rem;
		}
	}
}
.primary-panel__small-title {
	font-size: 1.7rem;
	font-weight: 600;

	@include wide {
		font-size: 2rem;
	}
	&:not(:first-child) {
		margin-top: 4px;
	}
}
.careers-division-detail-interview__text {
	font-size: 1.2rem;
	line-height: 1.5;

	@include wide {
		font-size: 1.6rem;
	}
	&:not(:first-child) {
		margin-top: 15px;
	}
}
.primary-panel__small-text {
	font-size: 1.2rem;
	line-height: 1.5;

	@include wide {
		font-size: 1.4rem;
	}
	&:not(:first-child) {
		margin-top: 15px;

		@include wide {
			margin-top: 20px;
		}
	}
}
.primary-panel__icon {
	position: absolute;
	right: 16px;
	bottom: 16px;
	display: block;
	width: 20px;
	height: 8px;
	background: url("/assets/img/common/arrow-next-red.svg") center center;
	background-size: cover;

	@include wide {
		transition: transform .2s;
		transform-origin: right;
	}
}


// 小さいパネル

.secondary-panel-content {
	@include wide {
		max-width: 1248px;
		margin-right: auto;
		margin-left: auto;
		&.is-no-slider {
			max-width: 1200px;
		}
	}
	.slick-slider__utility {
		@include sp {
			position: relative;
			z-index: 5;
			margin-top: -10px;
		}
		@include wide {
			margin-top: 40px;
		}
	}
}
.secondary-panel-list {
	@include sp {
		margin-top: -30px;
	}
	&:not(.is-no-slider) {
		@include sp {
			width: 81vw;
			margin-right: auto;
			margin-left: auto;
		}
	}
	&.is-no-slider {
		@include wide {
			display: flex;
			flex-wrap: wrap;
		}
	}
	.slick-list {
		@include wide {
			overflow: visible;
		}
	}
}
.secondary-panel {
	@include sp {
		overflow: hidden;
		padding: 30px 20px 40px;
	}
	@include wide {
		//width: 312px;
		padding-right: 24px;
		padding-left: 24px;
	}
	.secondary-panel-list.is-no-slider & {
		@include wide {
			padding-right: 0;
			padding-left: 0;
		}
		@include tab {
			width: calc(50% - 24px);
			&:nth-child(odd) {
				margin-right: 48px;
			}
			&:nth-of-type(n+3) {
				margin-top: 48px;
			}
		}
		@include pc {
			width: calc((100% - 4% * 3) / 4);
			&:not(:last-child) {
				margin-right: 4%;
			}
		}
	}
}
.secondary-panel__inner {
	display: block;
	display: flex;
	flex-direction: column;
	height: 100%;
	cursor: pointer;
	box-shadow: $box_shadow;

	@include wide {
		transition: box-shadow .3s;
		&:hover {
			box-shadow: $box_shadow_hover;
		}
	}
}
.secondary-panel__image {
	overflow: hidden;
	img {
		@include wide {
			transition: transform .3s;
		}
	}
	a:hover & {
		@include wide {
			img {
				transform: scale(1.03,1.03);
			}
		}
	}
}
.secondary-panel__textarea {
	position: relative;
	padding: 19px 14px 30px;
	background: #fff;

	flex-grow: 1;
}
.secondary-panel__textbox {
	line-height: 1.5;
	margin-bottom: 14px;
	padding-right: 4px;
	padding-left: 5px;
	letter-spacing: 0;
}
.secondary-panel__text {
	margin-bottom: 10px;
}
.secondary-panel__text-sub {
	font-weight: bold;
}
.secondary-panel__icon {
	position: absolute;
	right: 15px;
	bottom: 15px;
	display: block;
	width: 12px;
	height: 12px;
	background: url("/assets/img/common/icon-external-red.svg") center;
	background-size: cover;
}
