@use "../base/summarize" as * ;
.top-mv {
	position: relative;
	@include pc {
		height: 600px;
	}
}
.top-mv__heading {
	font-size: 2.5rem;
	font-weight: 600;
	line-height: 1.75;
	color: $white;
	width: fit-content;
	margin: 0 auto 1.7rem;
	&--bg-first {
		padding: 0.6rem 0.3rem 0.6rem 0.5rem;
		@include pc {
			padding: 0.6rem 0.6rem 0.6rem 2.6rem;
		}
	}
	&--bg-second {
		display: block;
		padding: 0 0.3rem 0.6rem 0.5rem;
		@include pc {
			padding: 0 1.9rem 1.9rem 2.6rem;
		}
	}
	@include pc {
		font-size: 4rem;
		letter-spacing: 0.1em;
		width: 100%;
		margin: 0 0 1.5rem;
	}
}
.top-mv__heading--black {
	.top-mv__heading--bg-first {
		background-color: $black;
	}
	.top-mv__heading--bg-second {
		display: block;
		width: fit-content;
		background-color: $black;
	}
}
.top-mv__button {
	@include pc {
		margin-left: 2.6rem;
	}
}
.top-mv__button-text {
	@include pc {
		font-size: 1.8rem;
	}
}
.top-mv__second{
	width: 100%;
	height: 54.6rem;
	overflow: hidden;
	padding-top: 2rem;
	background-color: $black;
	@include pc {
		height: 60rem;
		padding-top: 1.5rem;
	}
}
.top-mv__second-list{
	display: flex;
	overflow: hidden;
	position: relative;
	height: 11rem;
	margin-bottom: 2rem;
	@include pc {
		height: 17rem;
		margin-bottom: 3rem;
	}
}
.top-mv__second-list:nth-child(even){
	flex-direction: row-reverse;
}
.top-mv__second-item{
	position: relative;
	width: auto;
	height: 100%;
}
.top-mv__second-img{
	width: auto;
	height: 100%;
}
.top-mv__second-list:nth-child(even) li{
	animation: loop-slideRight 20s infinite linear 1s both;
}
.top-mv__second-list:nth-child(odd) li{
	animation: loop-slideLeft 20s infinite linear 1s both;
}
@keyframes loop-slideLeft {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(-100%);
	}
}
@keyframes loop-slideRight {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(100%);
	}
}


.top-mv__news{
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	min-height: 9.4rem;
	background-color: $black;
	z-index: 4;
	@include pc {
		min-height: auto;
	}
}
.top-mv__news-inner{
	min-height: 9.4rem;
	padding: 1.2rem 2.4rem 1.3rem 2rem;
	@include pc {
		display: flex;
		max-width: 129.6rem;
		min-height: auto;
		padding: 1.8rem 4.8rem;
		margin: 0 auto;
	}
}
.top-mv__news-link{
	position: relative;
	display: block;
	&:after {
		position: absolute;
		top: calc((100% - 0.6rem) / 2);
		right: 0;
		content: "";
		display: block;
		background-image: url(/assets/img/top/top-news-arrow.svg);
		background-size: cover;
		background-repeat: no-repeat;
		width: 1.6rem;
		height: 0.6rem;
		@include pc {
			display: none;
		}
	}
}
.top-mv__news-content{
	display: flex;
	align-items: center;
	margin-bottom: 0.3rem;
	@include pc {
		margin-bottom: 0;
		margin-right: 1rem;
	}
}
.top-mv__news-title{
	color: $white;
	font-size: 1.2rem;
	margin-right: 1rem;
	padding: 0 0.8rem;
	border: .1rem solid $red;
	white-space: nowrap;
	@include pc {
		font-size: 1.4rem;
		padding: 0 1rem;
		border: .2rem solid $red;
	}
}
.top-mv__news-date {
	color: $white;
	font-family: $font_barlow_c;
	font-size: 1.4rem;
	font-weight: 600;
	letter-spacing: 0.07rem;
	white-space: nowrap;
	@include pc {
		font-size: 1.4rem;
	}
}
.top-mv__news-text{
	color: $white;
	font-weight: bold;
	font-size: 1.2rem;
	line-height: 1.75;
	text-decoration: underline;
	// width: 30.1rem;
	width: 91%;
	-webkit-line-clamp: 2;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
	@include pc {
		font-size: 1.6rem;
		width: 100%;
		-webkit-line-clamp: 1;
		&:hover {
			text-decoration: none;
		}
	}
}


.top-recruit {
	margin-top: 40px;
	margin-bottom: 90px;

	@include wide {
		margin-top: 60px;
		margin-bottom: 120px;
	}
}
.top-recruit__heading {
	margin-bottom: 20px;

	@include wide {
		margin-bottom: 40px;
	}
}
.top-recruit__button {
	margin-top: 40px;
	margin-right: auto;
	margin-left: auto;

	@include wide {
		margin-top: 80px;
	}
}


.top-pickup {
	padding-top: 40px;
	padding-bottom: 80px;
	background: $black;

	@include wide {
		padding-top: 90px;
		padding-bottom: 130px;
	}
}
.top-pickup__section-inner {
	@include pc {
		display: flex;
	}
}
.top-pickup__content {
	@include pc {
		display: flex;
	}
}
.top-pickup__box {
	margin-top: 40px;
	background: rgba($dark_gray,.2);

	@include pc {
		&:nth-child(odd) {
			margin-right: 48px;
		}
		&--data{
			width: calc(50% - 24px);
		}
	}
}
.top-pickup__box__image {
	overflow: hidden;
	img {
		@include wide {
			transition: transform .3s;
		}
	}
	a:hover & {
		@include wide {
			img {
				transform: scale(1.03,1.03);
			}
		}
	}
}
.top-pickup__box__outline {
	display: flex;
	align-items: center;
	padding: 15px 20px;
	&:before {
		height: 40px;
		content: "";

		@include wide {
			height: 30px;
		}
	}
	&:after {
		display: block;
		flex: none;
		width: 8px;
		height: 8px;
		margin-right: 0;
		margin-left: auto;
		content: "";
		transition: border .4s;
		transform: rotate(45deg);
		border: 1px solid $dark_gray;
		border-width: 1px 1px 0 0;
	}
	a:hover & {
		@include wide {
			&:after {
				border-color: $white;
			}
		}
	}
	&.external_link {
		&:after {
			background-image: url(/assets/img/common/icon-external-gray.png);
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
			transform: rotate(0);
			border: none;
			width: 12px;
			height: 12px;

		}
	}
}
.top-pickup__box__heading {
	font-size: 1.4rem;
	font-weight: normal;
	line-height: 1.5;
	position: relative;
	height: 100%;
	margin-right: 10px;
	color: $white;
	&:after {
		position: absolute;
		bottom: 2px;
		left: 0;
		display: block;
		width: 100%;
		height: 1px;
		content: "";
		transition: opacity .4s;
		opacity: 0;
		background: $white;
	}
	a:hover & {
		@include wide {
			&:after {
				opacity: 1;
			}
		}
	}
}


.top-news {
	overflow: hidden;
	padding-top: 80px;
	padding-bottom: 80px;

	@include wide {
		padding-top: 100px;
		padding-bottom: 100px;
	}
	.section-inner {
		@include wide {
			max-width: 100%;
		}
	}
}
.top-news__slider {
	margin-top: 34px;
}
.top-news__button {
	margin-top: 54px;
	margin-right: auto;
	margin-left: auto;
	@include wide {
		margin-top: 110px;
	}
}

.page-top .header,
.page-top .top-mv,
.page-top .top-content {
	opacity: 0;
}
.page-top .header.animation,
.page-top .top-mv.animation,
.page-top .top-content.animation {
    animation-name: topOpacity;
    animation-duration: 3s;
    animation-fill-mode: forwards;
}
@keyframes topOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}


// スライダースタイル
.slider{
	position: relative;
}
.slider__list{
	position: relative;
    width: 100%;
	height: 54.6rem;
	margin: 0 auto;
	background-color: $black;
	@include pc {
		height: 60rem;
	}
}
.slider__item{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
}
.slider__img{
	display: block;
	width: 100%;
	height: 54.6rem;
	object-fit: cover;
	@include pc {
		height: 60rem;
	}
}
// 切り替えアニメーション
.slider-animation{
	position: absolute;
	top: 0;
	left: 0;
	width: 0;
	height: 54.6rem;
	background-color: $black;
	z-index: 4;
	transition: width 1s ease-in-out;
	animation-name: sliderAnimation;
	animation-duration: 2s;
	@include pc {
		height: 60rem;
	}
}
@keyframes sliderAnimation {
	15% {
		left: 100%;
		width: 0;
	}
	40%{
		left: 0;
		width: 100%;
	}
	50%{
		left: 0;
		width: 100%;
	}
	70%{
		left: 0;
		width: 0;
	}
}
// スライダー上コンテンツ
.slider__content {
	position: absolute;
	top: 14.7rem;
	left: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	padding: 0 0.5rem;
	z-index: 5;
	@include pc {
		top: 13.5rem;
		left: calc((100% - 104rem) / 2);
		width: 104rem;
		align-items: flex-start;
	}
}