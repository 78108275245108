@use "../base/summarize" as * ;
// mv

.careers-office-workstyle-mv {
	@include wide {
		height: 300px;
	}
	.under-mv__heading {
		font-size: 2.4rem;
		line-height: 1.75;
		letter-spacing: .1em;
		color: $black;

		@include wide {
			font-size: 3.6rem;
			letter-spacing: .1em;
		}
	}
}

// intro

.careers-office-workstyle-intro {
  background: #fff;
  padding-top: 50px;
  padding-bottom: 70px;
  @include wide {
    padding-top: 110px;
    padding-bottom: 130px;
  }
}
.careers-office-workstyle-intro__text {
  font-size: 1.8rem;
  line-height: 1.75;
  letter-spacing: 0.02em;
  @include wide {
    text-align: center;
  }
  &+& {
    margin-top: 1.8em;
    @include wide {
      margin-top: 2em;
    }
  }
  sup {
    display: inline-block;
    position: relative;
    top: -0.3em;
  }
}
.careers-office-workstyle-intro__text__note {
  display: inline-block;
  font-size: 1.4rem;
  line-height: 1.5;
  margin-top: 10px;
}

// case

.careers-office-workstyle-case {
  padding-top: 13vw;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  @include wide {
    padding-top: 85px;
    padding-bottom: 80px;
  }
  &--1 {
    background-image: url("/assets/img/careers/office/work-style/case-texture01_sp.jpg");
    @media (min-width: (800px)) {
      background-image: url("/assets/img/careers/office/work-style/case-texture01_pc.jpg");
    }
  }
  &--2 {
    background-image: url("/assets/img/careers/office/work-style/case-texture02_sp.jpg");
    padding-bottom: 10vw;
    @media (min-width: (800px)) {
      padding-top: 80px;
      padding-bottom: 80px;
      background-image: url("/assets/img/careers/office/work-style/case-texture02_pc.jpg");
    }
  }
  &--3 {
    background-image: url("/assets/img/careers/office/work-style/case-texture03_sp.jpg");
    padding-bottom: 0;
    @media (min-width: (800px)) {
      background-image: url("/assets/img/careers/office/work-style/case-texture03_pc.jpg");
    }
  }
  &--4 {
    background-image: url("/assets/img/careers/office/work-style/case-texture04_sp.jpg");
    padding-top: 15vw;
    padding-bottom: 11vw;
    @media (min-width: (800px)) {
      padding-top: 120px;
      padding-bottom: 60px;
      background-image: url("/assets/img/careers/office/work-style/case-texture04_pc.jpg");
    }
  }
}
.careers-office-workstyle-case__head {
  @include wide {
    max-width: calc(1008px + #{$side_padding_pc} * 2);
  }
}
.careers-office-workstyle-case__label {
  width: 36.27vw;
  @media (min-width: (800px)) {
    width: 17.27vw;
  }
  @media (min-width: (1441px)) {
    width: 245px;
  }
}
.careers-office-workstyle-case__heading {
  font-family: $font_kurenaido;
  font-weight: normal;
  font-size: 6.3vw;
  line-height: 1.458;
  transform: rotate(-2deg);
  letter-spacing: 0;
  @media (min-width: (800px)) {
    font-size:2.8vw;
  }
  @media (min-width: (1441px)) {
    font-size: 4.0rem;
    margin-top: -10px;
  }
  &--white {
    color:#FFF;
  }
  br {
    @media (min-width: (800px)) {
      display: none;
    }
  }
}
.careers-office-workstyle-case__body {
  position: relative;
  @media (min-width: (800px)) {
    max-width: 1440px;
    margin-right: auto;
    margin-left: auto;
  }
  .careers-office-workstyle-case--1 & {
    margin-bottom: -24vw;
    @media (min-width: (800px)) {
      margin-top: 60px;
      margin-bottom: 0;
    }
  }
  .careers-office-workstyle-case--2 & {
    margin-top: 8vw;
    @media (min-width: (800px)) {
      margin-top: -4%;
    }
  }
  .careers-office-workstyle-case--3 & {
    margin-top: -8vw;
    margin-bottom: -14vw;
    @media (min-width: (800px)) {
      margin-top: -4%;
      margin-bottom: -4.5%;
    }
  }
  .careers-office-workstyle-case--4 & {
    margin-top: -31vw;
    @media (min-width: (800px)) {
      margin-top: -1.5%;
    }
  }
}
.careers-office-workstyle-case__text-area {
  position: absolute;
  @media (min-width: (800px)) {
    width: 43%;
  }
  .careers-office-workstyle-case--1 & {
    top: 130vw;
    right: 0;
    padding:0 12vw 0 15vw;
    @media (min-width: (800px)) {
      padding: 0;
      top: 48%;
      right:19.5%;
    }
  }
  .careers-office-workstyle-case--2 & {
    top: 127vw;
    right: 0;
    padding:0 13.6vw 0 13.5vw;
    @media (min-width: (800px)) {
      padding: 0;
      top: 48%;
      right:15.5%;
    }
  }
  .careers-office-workstyle-case--3 & {
    top: 128vw;
    right: 0;
    padding:0 13.6vw 0 13.5vw;
    @media (min-width: (800px)) {
      width: 40.2%;
      padding: 0;
      top: 36%;
      right:12.5%;
    }
  }
  .careers-office-workstyle-case--4 & {
    top: 167vw;
    right: 0;
    padding:0 13.6vw 0 13.5vw;
    @media (min-width: (800px)) {
      width: 46.8%;
      padding: 0;
      top: 43%;
      right:11.9%;
    }
  }
}
.careers-office-workstyle-case__text {
  font-size: 4.8vw;
  line-height: 1.75;
  @media (min-width: (800px)) {
    font-size: 1.25vw;
    line-height: 2;
  }
  @media (min-width: (1441px)) {
    font-size: 1.8rem;
  }
  &+& {
    margin-top: 1.8em;
  }
}

// back

.careers-office-workstyle-back {
  background: #fff;
  padding-top: 90px;
  padding-bottom: 80px;
  @include wide {
    padding-top: 130px;
  }
  .basic-button {
    margin-right: auto;
    margin-left: auto;
  }
}