@use "../base/summarize" as * ;
// パーツ

.article-date {
	font-family: $font_barlow_c;
	font-size: 1.4rem;
	letter-spacing: .06em;
	color: $dark_gray;

	@include wide {
		font-size: 1.2rem;
	}
}
.article-category {
	font-size: 1.2rem;
	color: $red;

	@include wide {
		font-size: 1rem;
	}
}
.article-utility {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 72px;
}
.article-pager {
	display: flex;
	align-items: center;
}
.article-pager__item {
	width: 40px;
	height: 40px;
	margin: 0 8px;
	&:nth-of-type(n+4) {
		@include sp {
			display: none;
		}
	}
	a {
		font-family: $font_barlow_c;
		font-size: 1.8rem;
		font-weight: 600;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		color: $dark_gray;
		border: 1px solid $warm_gray;
		border-radius: 6px;

		@include wide {
			transition: .3s;
			&:hover {
				color: $white;
				border-color: $red;
				background: $red;
			}
		}
	}
	&--current {
		a {
			color: $white;
			border-color: $red;
			background: $red;
		}
	}
}
.article-arrow {
	a {
		font-size: 0;
		line-height: 0;
		position: relative;
		display: block;
		overflow: hidden;
		width: 48px;
		height: 32px;
		cursor: pointer;
		white-space: nowrap;
		text-indent: 100%;

		@include wide {
			transition: transform .2s;
			&:hover {
				transform: scaleX(.85);
			}
		}
		&:after {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			display: block;
			width: 100%;
			height: calc(100% - 20px);
			margin: auto;
			content: "";
			background: no-repeat center center;
			background-size: 100% auto;
		}
	}
	&--prev {
		a {
			transform-origin: center left;
			&:after {
				background-image: url("/assets/img/common/arrow-prev-red-wide.svg");
			}
		}
	}
	&--next {
		a {
			transform-origin: center right;
			&:after {
				background-image: url("/assets/img/common/arrow-next-red-wide.svg");
			}
		}
	}
}

// ボックス

.article-box {
	width: 100%;
}
.article-box__inner {
	display: block;
	height: 100%;
	box-shadow: $box_shadow;

	@include wide {
		transition: .4s;
		&:hover {
			box-shadow: $box_shadow_hover;
		}
	}
}
.article-box__image {
	position: relative;
	overflow: hidden;
	padding-top: 56.062%;
	background-color: $white;
	img {
		position: absolute;
		top: 0;
		left: 0;

		width: 100%;
		height: 100%;
		object-fit: contain;
		@include wide {
			transition: .3s;
		}
	}
	a:hover & {
		img {
			@include wide {
				transform: scale(1.04,1.04);
			}
		}
	}
}
.article-box__outline {
	padding: 20px;

	@include wide {
		min-height: 170px;
	}
}
.article-box__data {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.article-box__title {
	font-size: 1.6rem;
	font-weight: normal;
	line-height: 1.5;
	margin-top: 6px;

	@include wide {
		font-size: 1.4rem;
		line-height: 1.75;
		margin-top: 12px;
	}
}

// 一覧

.article-box-list {
	@include wide {
		display: flex;
		flex-wrap: wrap;
	}
	.article-box {
		@include sp {
			&:not(:first-child) {
				margin-top: 30px;
			}
		}
		@include wide {
			margin-top: 60px;
		}
		@include tab {
			width: calc((100% - 48px * 1) / 2);
			&:not(:nth-child(2n)) {
				margin-right: 48px;
			}
			&:nth-child(-n+2) {
				margin-top: 0;
			}
		}
		@include pc {
			width: calc((100% - 48px * 3) / 4);
			&:not(:nth-child(4n)) {
				margin-right: 48px;
			}
			&:nth-child(-n+4) {
				margin-top: 0;
			}
		}
	}
}

// スライダー

.article-box-slider {
	@include wide {
	}
	.slick-list {
		overflow: visible;
	}
	.slick-slide {
		@include wide {
			width: 312px;
		}
	}
	.article-box {
		.article-category {
			@include sp {
				font-size: 1.2rem;
			}
		}
		.article-date {
			@include sp {
				font-size: 1.2rem;
			}
		}
	}
	.article-box__title {
		@include sp {
			margin-top: 12px;
		}
	}
	.article-box__outline {
		min-height: 170px;
	}
	.article-box__inner {
		display: block;
		margin-right: 15px;
		margin-left: 15px;
		position: relative;
		@include wide {
			margin-right: 24px;
			margin-left: 24px;
		}
		&:before {
			content: '';
			display: block;
			width: 100%;
			height: 100%;
			background: #fff;
			position: absolute;
			left: 0;
			top: 0;
			opacity: 0.8;
			z-index: 3;
			pointer-events: none;
			transition: opacity .3s;
			opacity: 0;
		}
	}
	.slick-slide:not(.slick-active) {
		.article-box__inner {
			&:before {
				opacity: .8;
			}
		}
	}
	.article-box__title {
		@include sp {
			font-size: 1.4rem;
		}
	}
}
.article-box-slider__list {
	width: 78.4vw;
	margin-right: auto;
	margin-left: auto;
	@include wide {
		width: 100%;
		max-width: 1248px;
	}
}

// 記事詳細

.article-single {
	padding-top: 40px;
	padding-bottom: 90px;

	@include wide {
		padding-bottom: 100px;
	}
}
.article-single__inner {
	&.section-inner {
		@include wide {
			max-width: calc(992px + #{$side_padding_pc} * 2);
		}
	}
}
.article-single-head {
	display: flex;
	align-items: center;
	margin-bottom: 30px;
	.article-date {
		margin-right: 20px;

		@include wide {
			font-size: 1.6rem;
		}
	}
	.article-category {
		@include wide {
			font-size: 1.4rem;
		}
	}
}
.article-title {
	font-size: 2.8rem;
	line-height: 1.5;

	@include wide {
		font-size: 3.6rem;
	}
}
.article-eyecatch {
	margin-top: 15px;
	margin-bottom: 25px;

	@include wide {
		margin-top: 25px;
		margin-bottom: 50px;
	}
}
.article-edit {
	font-size: 1.8rem;
	line-height: 1.75;
	p {
		margin: 1.5em 0;

		@include wide {
			margin: 1.8em 0;
		}
	}
	h2,
	h3 {
		& +p {
			margin-top: 0;
		}
	}
	h2 {
		font-size: 2rem;
		line-height: 1.5;
		margin-bottom: 20px;
		&:not(:first-child) {
			margin-top: 50px;

			@include wide {
				margin-top: 60px;
			}
		}
	}
	h3 {
		font-size: 1.6rem;
		line-height: 1.5;
		margin-bottom: 20px;
		&:not(:first-child) {
			margin-top: 40px;
		}
	}
	a {
		font-weight: 600;
		position: relative;
		color: $red;
		&:after {
			position: absolute;
			bottom: 1px;
			left: 0;
			display: block;
			width: 100%;
			height: 1px;
			content: "";
			transition: opacity .4s;
			opacity: 0;
			background: $red;
		}
		&:hover {
			&:after {
				@include wide {
					opacity: 1;
				}
			}
		}
	}
	img {
		max-width: 100%;
		width: auto;
	}
}
.article-edit__caption {
	font-size: 1.4rem;
	margin-top: 35px;
	margin-bottom: 40px;
	padding-top: 45px;
	border-top: 1px solid #c8cdd6;
	&:not(:last-child) {
		@include wide {
			margin-bottom: 80px;
		}
	}
}
.article-back {
	margin-top: 85px;
	a {
		margin-right: auto;
		margin-left: auto;
	}
}

// 記事内の画像ブロック

.article-image-block {
	margin: 20px auto;

	@include wide {
		margin: 82px auto;
	}
}
.article-image-column {
	margin: 40px 0;

	@include wide {
		display: flex;
		margin: 82px 0;
	}
	.article-image-block {
		width: 100%;

		@include wide {
			margin-top: 0;
			margin-bottom: 0;
		}
		&--m {
			@include wide {
				&:not(:last-child) {
					margin-right: 48px;
				}
			}
		}
		&--s {
			@include wide {
				&:not(:last-child) {
					margin-right: 34px;
				}
			}
		}
	}
}
.article-flexbox {
	margin: 20px auto;

	@include wide {
		display: flex;
		align-items: center;
		margin: 82px auto;
		&--reverse {
			flex-direction: row-reverse;
		}
	}
	.article-image-block {
		margin-top: 0;

		@include wide {
			flex: none;
			width: 100%;
			max-width: calc(50% - 24px);
			margin-right: 48px;
			margin-bottom: 0;
		}
	}
	&--reverse {
		.article-image-block {
			@include wide {
				margin-right: 0;
				margin-left: 48px;
			}
		}
	}
	h4 +p {
		margin-top: 20px;
	}
}


// カテゴリメニュー

.article-menu {
	overflow: auto;
	text-align: center;
}
.article-menu__list {
	display: inline-flex;
}
.article-menu__item {
	font-size: 1.4rem;
	white-space: nowrap;
	letter-spacing: .02em;
	border-right: 1px solid #dcdfe0;

	@include wide {
		font-size: 1.8rem;
	}
	&:last-child {
		border-right: none;
	}
	a {
		display: block;
		padding: 2px 12px;

		@include wide {
			padding: 0 20px;
			transition: color .3s;
			&:hover {
				color: $red;
			}
		}
	}
	&.is-current {
		a {
			color: $red;
		}
	}
}
