@use "../base/summarize" as * ;
.primary-slider {
	&:not(.primary-slider--loaded) {
		.slick-arrow {
			opacity: 0;
		}
	}
	.slick-arrow {
		font-size: 0;
		line-height: 0;
		overflow: hidden;
		cursor: pointer;
		white-space: nowrap;
		text-indent: 100%;
		border: none;
		width: 48px;
		height: 48px;
		-webkit-appearance: none;
		background: #fff;
		border-radius: 50%;
		box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1), 10px 10px 15px rgba(0, 0, 0, 0.1);
		position: absolute;
		z-index: 5;
		top: 0;
		transform: translateY(-50%);
		transition:opacity .2s;
		@include wide {
			width: 64px;
			height: 64px;
		}
		&.slick-prev {
			left:-22px;
			@include wide {
				left:-16px;
			}
		}
		&.slick-next {
			right: -22px;
			@include wide {
				right: -16px;
			}
		}
		&.slick-disabled {
			opacity: 0;
			pointer-events: none;
		}
		&:after {
			content: '';
			display: block;
			width: 12px;
			height: 12px;
			border: solid $red;
			border-width:  0 0 2px 2px;
			transform: rotate(45deg);
			position: absolute;
			left: calc(50% - 4px);
			top: calc(50% - 6px);
			transition: margin .2s;
			@include wide {
				width: 15px;
				height: 15px;
				top: calc(50% - 8px);
			}
		}
		&.slick-next {
			&:after {
				border-width: 2px 2px 0 0;
				left: calc(50% - 8px);
			}
		}
		@include wide {
			&:hover {
				&:after {
					margin-left: -2px;
				}
				&.slick-next {
					&:after {
						margin-left: 2px;
					}
				}
			}
		}
	}
	.slick-dots {
		display: flex;
		align-items: center;
		justify-content: center;

		li {
			width: 30px;
			height: 30px;

			@include wide {
				margin-right: 8px;
				margin-left: 8px;
			}
			button {
				font-size: 0;
				line-height: 0;
				position: relative;
				overflow: hidden;
				width: 100%;
				height: 100%;
				cursor: pointer;
				white-space: nowrap;
				text-indent: 100%;
				border: none;
				background: none;

				-webkit-appearance: none;
				&:after {
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					display: block;
					width: 10px;
					height: 10px;
					margin: auto;
					content: "";
					transition: background .4s;
					border-radius: 50%;
					background: $warm_gray;

					@include wide {
						width: 12px;
						height: 12px;
					}
				}
				&:hover {
					&:after {
						@include wide {
							background: $red;
						}
					}
				}
			}
			&.slick-active {
				button {
					&:after {
						background: $red;
					}
				}
			}
		}
	}
}
.primary-slider__utility {
	display: flex;
	align-items: center;
	justify-content: space-between;
	.slick-dots {
		order: 2;
		margin-right: auto;
		margin-left: auto;
	}
	.slick-prev {
		order: 1;
	}
	.slick-next {
		order: 3;
	}
}


.primary-panel-list--slick {
	.slick-arrow {
		font-size: 0;
		line-height: 0;
		overflow: hidden;
		cursor: pointer;
		white-space: nowrap;
		text-indent: 100%;
		border: none;
		width: 48px;
		height: 48px;
		-webkit-appearance: none;
		background: #fff;
		border-radius: 50%;
		box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1), 10px 10px 15px rgba(0, 0, 0, 0.1);
		position: absolute;
		z-index: 5;
		top: 0;
		transform: translateY(-50%);
		transition:opacity .2s;
		@include wide {
			width: 64px;
			height: 64px;
		}
		&.slick-prev {
			left:-22px;
			@include wide {
				left:-16px;
			}
		}
		&.slick-next {
			right: -22px;
			@include wide {
				right: -16px;
			}
		}
		&.slick-disabled {
			opacity: 0;
			pointer-events: none;
		}
		&:after {
			content: '';
			display: block;
			width: 12px;
			height: 12px;
			border: solid $red;
			border-width:  0 0 2px 2px;
			transform: rotate(45deg);
			position: absolute;
			left: calc(50% - 4px);
			top: calc(50% - 6px);
			transition: margin .2s;
			@include wide {
				width: 15px;
				height: 15px;
				top: calc(50% - 8px);
			}
		}
		&.slick-next {
			&:after {
				border-width: 2px 2px 0 0;
				left: calc(50% - 8px);
			}
		}
		@include wide {
			&:hover {
				&:after {
					margin-left: -2px;
				}
				&.slick-next {
					&:after {
						margin-left: 2px;
					}
				}
			}
		}
	}
}