@use "../base/summarize" as * ;
.careers-office-data-bg {
	padding-bottom: 46px;
	background: $bg_blue;

	@include wide {
		padding-bottom: 155px;
	}
}
.careers-office-data-heading {
	margin-bottom: 10px;

	@include wide {
		margin-bottom: 15px;
	}
}
.careers-office-data-date {
	font-size: 1.2rem;
	margin-bottom: 40px;
	text-align: center;
}

// box レイアウト

.careers-office-data-boxwrap {
	@media (min-width: (900px)) {
		display: flex;
	}
	&:not(:last-child) {
		@media (min-width: (900px)) {
			margin-bottom: 3.5%;
		}
	}
	&--column2 {
		.careers-office-data-box {
			@media (min-width: (900px)) {
				width: 50%;
			}
		}
	}
	&--column3 {
		.careers-office-data-box {
			@media (min-width: (900px)) {
				width: calc(100% / 3);
			}
		}
	}
}
.careers-office-data-box {
	width: 100%;
	margin-bottom: 24px;
	background: $white;
	@media (min-width: (900px)) {
		margin-bottom: 0;
	}
	&:not(:last-child) {
		@include wide {
			margin-right: 4%;
		}
	}
}
.careers-office-data-box__heading {
	font-size: 2rem;
	font-weight: 600;
	line-height: 1.5;
	padding-top: 30px;
	padding-bottom: 10px;
	text-align: center;
	letter-spacing: .05em;

	@include wide {
		font-size: 2.4rem;
	}
	@media (min-width: (900px)) {
		padding-top: 60px;
	}
	span {
		display: block;
		font-size: 1.2rem;
		margin-top: 0.2rem;
		font-weight: 400;
		&.red {
			color: $red;
		}
	}
}
.careers-office-data-box__number {
	font-size: 2.4rem;
	font-weight: 700;
	line-height: 1;
	text-align: center;

	@include wide {
		font-size: 4rem;
	}
	@media (min-width: (900px)) {
		font-size: 3rem;
	}
	em {
		font-family: $font_barlow;
		font-size: 10rem;
		font-style: normal;
		line-height: 1;

		@include wide {
			font-size: 15rem;
		}
		@media (min-width: (900px)) {
			font-size: 12rem;
		}
		small {
			font-size: .5em;
			position: relative;
			top: -5px;
			display: inline-block;
			color: initial;
		}
		&.red {
			color: $red;
		}
	}
}
.careers-office-data-box__text {
	font-size: 1.4rem;
	line-height: 1.75;
}
.careers-office-data-box__note {
	font-size: 1.2rem;
	line-height: 1.5;

	@include wide {
		font-size: 1.4rem;
		line-height: 1.75;
	}
}
.careers-office-data-box__annotation {
	text-align: center;
	font-size: 1.2rem;
	margin-bottom: 2px;
	&--mt{
		margin-top: 6px;
		margin-bottom: 0;
	}
}

// box 個別調整

.careers-office-data-box {
	// 01 ---------------------------

	&--01 {
		@include wide {
			display: flex;
			flex-direction: column;
		}
		.careers-office-data-box__heading {
			@include pc {
				padding-bottom: 40px;
			}
		}
		.careers-office-data-box--01__flex {
			display: flex;
			align-items: flex-end;

			@include wide {
				margin-top: auto;
				margin-bottom: 0;
			}
		}
		.careers-office-data-box--01__flex__outline {
			flex: none;
			margin-left: -10px;
			padding-right: 13px;
			padding-bottom: 40px;

			@include pc {
				padding-bottom: 18px;
			}
		}
		.careers-office-data-box--01__button {
			width: 100%;
			max-width: auto;
			margin-top: 10px;

			@include sp {
				font-size: 1.4rem;
				min-width: 120px;
				height: 40px;
			}
			@include wide {
				min-width: 206px;
				height: 48px;
				margin-top: 20px;
			}
		}
		.careers-office-data-box--01__flex__image {
			flex: 1;
			width: 40%;
			max-width: 300px;

			@include pc {
				width: 100%;
				max-width: 56.424%;
			}
		}
	}

	// 02 ---------------------------

	&--02 {
		@include wide {
			display: flex;
			flex-direction: column;
		}
		.careers-office-data-box--02__image {
			width: 100%;
			max-width: 335px;
			margin-right: auto;
			margin-left: auto;
			margin-top: 23px;

			@include wide {
				max-width: 580px;
				margin-top: auto;
				margin-bottom: 0;
			}
		}
	}

	// 03 ---------------------------

	&--03 {
		padding: 0 30px 35px 30px;

		@include pc {
			padding: 68px 90px 108px;
		}

		@media (min-width: (900px)) {
			padding-top: 35px;
		}
		.careers-office-data-box__heading {
			padding-bottom: 30px;
			@media (min-width: (900px)) {
				position: absolute;
				top: 50%;
				left: 50%;
				padding-top: 15%;
				padding-bottom: 0;
				transform: translate(-50%,-50%);
				@media (max-width: (1400px)) {
					font-size: 2vw;
				}
			}
		}
		.careers-office-data-box--03__flex {
			@media (min-width: (900px)) {
				display: flex;
				align-items: center;
			}
		}
		.careers-office-data-box--03__image {
			position: relative;
			max-width: 375px;
			margin-right: auto;
			margin-left: auto;
			@media (min-width: (900px)) {
				flex: none;
				width: 57%;
				max-width: 100%;
				margin: 0 40px 0 0;
			}
			@media (min-width: (1200px)) {
				margin-right: 90px;
			}
		}
		.careers-office-data-box--03__list {
			@include sp {
				margin-top: 40px;
			}
		}
		.careers-office-data-box--03__list__text {
			font-size: 1.6rem;
			font-weight: 600;
			display: flex;
			letter-spacing: .02em;

			@include wide {
				font-size: 2rem;
			}
			&:not(:last-child) {
				margin-bottom: 10px;

				@include wide {
					margin-bottom: 20px;
				}
			}
			&:before {
				display: block;
				flex: none;
				width: 16px;
				height: 16px;
				margin-top: 7px;
				margin-right: 13px;
				content: "";
				border-radius: 50%;
				background: #000;

				@include wide {
					width: 20px;
					height: 20px;
					margin-top: 9px;
					margin-right: 20px;
				}
			}
			&--01 {
				&:before {
					background: #E60000;
				}
			}
			&--02 {
				&:before {
					background: #FF5555;
				}
			}
			&--03 {
				&:before {
					background: #FFC1C9;
				}
			}
			&--04 {
				&:before {
					background: #6BBAF4;
				}
			}
			&--05 {
				&:before {
					background: #585CFE;
				}
			}
			&--06 {
				&:before {
					background: #412A87;
				}
			}
			&--07 {
				&:before {
					background: #D6CDCD;
				}
			}
			&--08 {
				&:before {
					background: #958F8F;
				}
			}
			&--09 {
				&:before {
					background: #614F4F;
				}
			}
		}
	}

	// 04 ---------------------------

	&--04 {
		padding: 0 30px 30px 30px;
		text-align: center;

		@include wide {
			padding-bottom: 15px;
		}
		.careers-office-data-box__heading {
			padding-bottom: 20px;

			@include wide {
				padding-top: 35px;
				padding-bottom: 10px;
			}
		}
		.careers-office-data-box__text {
			display: inline-block;
			text-align: left;

			@include wide {
				max-width: 464px;
			}
		}
		.careers-office-data-box--04__image {
			max-width: 440px;
			margin-right: auto;
			margin-bottom: 20px;
			margin-left: auto;
		}
	}

	// 05 ---------------------------

	&--05 {
		@include wide {
			display: flex;
			flex-direction: column;
		}
		.careers-office-data-box__heading {
			padding-bottom: 20px;

			@include wide {
				padding-top: 35px;
			}
		}
		.careers-office-data-box__note {
			margin-top: 10px;

			@include wide {
				margin-top: 35px;
			}
		}
		.careers-office-data-box__number {
			text-align: left;
		}
		.careers-office-data-box--05__flex {
			display: flex;
			align-items: flex-end;

			@include wide {
				margin-top: auto;
				margin-bottom: 0;
			}
			@include tab {
				max-width: 500px;
				margin-right: auto;
				margin-left: auto;
			}
		}
		.careers-office-data-box--05__flex__outline {
			flex: none;

			@include sp {
				max-width: calc(100% - 150px);
				padding-right: 20px;
				padding-bottom: 25px;
			}
			@include sp-min {
				max-width: calc(100% - 100px);
			}
			@include wide {
				max-width: 290px;
				padding-right: 48px;
				padding-bottom: 60px;
			}
		}
		.careers-office-data-box--05__image {
			max-width: 190px;
			margin: 0 20px;

			@include wide {
				width: 33%;
				max-width: 100%;
			}
			@include pc {
				margin-right: 45px;
				margin-left: 45px;
			}
		}
	}

	// 06 ---------------------------

	&--06 {
		@media (min-width: (900px)) {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: flex-end;
		}
		.careers-office-data-box--06__outline {
			@media (min-width: (900px)) {
				flex: 1;
				margin-bottom: auto;
			}
		}
		.careers-office-data-box__heading {
			@include wide {
				padding-top: 35px;
			}
		}
		.careers-office-data-box__number {
			margin-bottom: 30px;
		}
		.careers-office-data-box--06__image {
			max-width: 176px;
			margin-right: auto;
			margin-left: auto;
		}
	}

	// 07 ---------------------------

	&--07 {
		text-align: center;
		@media (min-width: (900px)) {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: flex-end;
		}
		.careers-office-data-box--07__outline {
			@media (min-width: (900px)) {
				flex: 1;
				margin-bottom: auto;
			}
		}
		.careers-office-data-box__note {
			display: inline-block;
			margin-top: 10px;
			margin-bottom: 20px;
			text-align: left;

			@include wide {
				margin: 20px auto 10px;
				padding-right: 15px;
				padding-left: 15px;
			}
		}
		.careers-office-data-box__heading {
			@include wide {
				padding-top: 35px;
			}
		}
		.careers-office-data-box--07__image {
			max-width: 193px;
			margin-right: auto;
			margin-left: auto;
		}
	}

	// 08 ---------------------------

	&--08 {
		text-align: center;
		// @media (min-width: (900px)) {
		// 	display: flex;
		// 	flex-direction: column;
		// 	align-items: center;
		// 	justify-content: flex-end;
		// }
		// .careers-office-data-box--08__outline {
		// 	@media (min-width: (900px)) {
		// 		flex: 1;
		// 		margin-bottom: auto;
		// 	}
		// }
		.careers-office-data-box__note {
			display: inline-block;
			margin-top: 10px;
			margin-bottom: 20px;
			text-align: left;

			@include wide {
				margin-right: auto;
				margin-bottom: 10px;
				margin-left: auto;
				padding-right: 15px;
				padding-left: 15px;
			}
		}
		.careers-office-data-box__heading {
			@include wide {
				padding-top: 35px;
			}
		}
		.careers-office-data-box--08__image {
			max-width: 140px;
			margin-right: auto;
			margin-left: auto;
		}
	}

	// 09 ---------------------------

	&--09 {
		@include wide {
			display: flex;
			flex-direction: column;
		}
		.careers-office-data-box__note {
			position: relative;
			z-index: 2;
			max-width: 275px;
			margin-top: 10px;
			margin-right: auto;
			margin-bottom: -20px;
			margin-left: auto;

			@include wide {
				margin-bottom: -50px;
			}
			@include pc {
				max-width: 100%;
			}
		}
		.careers-office-data-box--09__image {
			width: 100%;
			margin-top: 3rem;
			margin-right: auto;
			margin-left: auto;

			@include wide {
				width: 100%;
				margin-top: 9rem;
				margin-bottom: 0;
			}
		}
	}

	// 10 ---------------------------

	&--10 {
		text-align: center;
		.careers-office-data-box--10__result {
			@include wide {
				display: inline-block;
			}
		}
		.careers-office-data-box--10__result__block {
			display: flex;
			align-items: flex-end;
			justify-content: space-between;
			width: calc(100% - 40px);
			max-width: 275px;
			margin: 0 auto;
			text-align: left;

			@include wide {
				justify-content: flex-start;
				width: 100%;
				max-width: 100%;
			}
			&:last-of-type {
				margin-top: 20px;
			}
			.careers-office-data-box__number {
				em {
					font-size: 4.6rem;
					position: relative;
					top: 6px;

					@include pc {
						font-size: 8rem;
						top: 8px;
					}
				}
			}
		}
		.careers-office-data-box--10__result__title {
			font-size: 1.6rem;
			font-weight: 600;
			letter-spacing: .1em;

			@include wide {
				font-size: 2.4rem;
				width: 145px;
			}
		}
		.careers-office-data-box__heading {
			@include wide {
				padding-bottom: 50px;
			}
		}
		.careers-office-data-box--10__button {
			width: calc(100% - 40px);
			max-width: 275px;
			margin-top: 40px;
			margin-right: auto;
			margin-left: auto;

			@include wide {
				margin-top: 82px;
				margin-left: 55px;
			}
			.basic-button {
				font-size: 1.4rem;
				width: 140px;
				height: 40px;

				@include wide {
					font-size: 1.6rem;
					width: 206px;
					height: 48px;
				}
			}
		}
		.careers-office-data-box--10__text {
			font-size: 1.2rem;
			width: calc(100% - 40px);
			max-width: 275px;
			margin-top: -25px;
			margin-right: auto;
			margin-left: auto;
			padding-bottom: 30px;
			text-align: right;

			@include wide {
				width: calc(100% - 60px);
				max-width: 100%;
				margin-top: 0px;
			}
		}
	}
}


// back button

.careers-office-data-back {
	padding-top: 70px;
	padding-bottom: 120px;
	background: $white;

	@include wide {
		padding-top: 116px;
		padding-bottom: 80px;
	}
	.basic-button {
		margin-right: auto;
		margin-left: auto;
	}
}
