@use "../base/summarize" as * ;
.header {
	position: relative;
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	width: 100%;
	height: $header_height_sp;
	background: $white;

	@include pc {
		height: $header_height_pc;
	}
	body.is-spNavOpen & {
		@include mobile {
			border-bottom: 1px solid $warm_gray;
		}
	}
}

// グロナビ

.header__navi {
	@include mobile {
		position: fixed;
		top: $header_height_sp;
		left: 0;
		overflow: auto;
		width: 100%;
		height: calc(100% - #{$header_height_sp});
		padding-bottom: 60px;
		transition: transform .4s, opacity .4s;
		background: $bg_gray;
		body:not(.is-spNavOpen) & {
			transform: translateX(100%);
			opacity: 0;
		}
	}
	@include pc {
		height: 40px;
		background-color: black;
	}
}
.header__navi__inner {
	@include pc {
		height: 100%;
	}
}
.header__menu {
	@include pc {
		display: flex;
		align-items: center;
		height: 100%;
		justify-content: center;
	}
}
.header__menu__item {
	@include mobile {
		border-bottom: 1px solid $warm_gray;
	}
	@include pc {
		height: 100%;
		position: relative;
	}
}
.header__menu__link {
	font-size: 1.6rem;
	font-weight: 600;
	position: relative;
	display: flex;
	align-items: center;

	@include mobile {
		min-height: 58px;
		padding: 10px 20px;
	}
	@include pc {
		color: $white;
		height: 100%;
		padding-bottom: 20px;
		padding-right: 32px;
		padding-left: 32px;
	}
	&:after {
		@include mobile {
			position: absolute;
			top: 50%;
			right: 18px;
			display: block;
			width: 8px;
			height: 8px;
			margin-top: -4px;
			content: "";
			transform: rotate(45deg);
			border: solid $red;
			border-width: 1px 1px 0 0;
		}
		@include pc {
			position: absolute;
			bottom: 0;
			left: 0;
			display: block;
			width: 100%;
			height: 4px;
			content: "";
			transition: .4s opacity;
			opacity: 0;
			border-radius: 2px;
			background: $red;
		}
	}
	@at-root .ua_pc &:hover {
		&:after {
			@include pc {
				opacity: 1;
			}
		}
	}
	@at-root .ua_tablet &.is-active {
		&:after {
			@include pc {
				opacity: 1;
			}
		}
	}
}

// 子メニュー

.header__menu__child {
	@include pc {
		position: absolute;
		top: 40px;
		left: 50%;
		transform: translateX(-50%);
		transition: opacity .3s;
		background: rgba($bg_gray,0.95);
		width: 100%;
		box-shadow:$box_shadow;
	}
	.header__menu__link:not(.is-active) +& {
		@include pc {
			pointer-events: none;
			opacity: 0;
		}
	}
	&__link__text {
		&--external {
			&::after {
				position: relative;
				top: -0.6rem;
				display: inline-block;
				content: '';
				background-image: url(/assets/img/common/icon-external-black.svg);
				background-size: cover;
				background-repeat: no-repeat;
				width: 0.75rem;
				height: 0.75rem;
				margin-left: 0.2rem;
			}
		}
	}
}
.header__menu__child__inner {
	@include pc {
		padding:15px 0;
	}
}
.header__menu__child__menu {
	@include mobile {
		border-top: 1px solid $warm_gray;
	}
}
.header__menu__child__link {
	font-size: 1.4rem;

	@include mobile {
		font-weight: 600;
		margin-left: 20px;
		border-bottom: 1px solid $warm_gray;
		&:last-child {
			border-bottom: none;
		}
	}
	a {
		display: flex;
		align-items: center;
		cursor: pointer;

		@include mobile {
			min-height: 48px;
			padding-left: 20px;
		}
		@include wide {
			transition:color .3s;
		}
		@include pc {
			position: relative;
			padding: 11px 0 11px 15px;
			transition:background .3s;
			&:before {
				content: '';
				display: block;
				width: 4px;
				height: 100%;
				background: $red;
				position: absolute;
				left: 0;
				top: 0;
				opacity: 0;
				transition:opacity  .3s;
			}
		}
		&:hover {
			@include wide {
				color:$red;
			}
			@include pc {
				color:inherit;
				font-weight: 600;
				background: #fff;
				&:before {
					opacity: 1;
				}
			}
		}
	}
}
