@use "../base/summarize" as * ;
.footer {
	position: relative;
}
.footer__pagetop {
	display: flex;
	align-items: center;
	justify-content: center;
	background: $white;

	@include sp {
		height: 120px;
	}
	@include wide {
		align-items: flex-start;
		padding: 25px 0;
	}
}
.footer__pagetop__link {
	display: block;
	padding: 30px;
	cursor: pointer;
	&:after {
		display: block;
		width: 30px;
		height: 30px;
		margin-top: 8px;
		content: "";
		transform: rotate(45deg);
		border: solid $red;
		border-width: 2px 0 0 2px;

		@include wide {
			transition: margin .25s;
		}
	}
	&:hover {
		@include wide {
			&:after {
				margin-top: -1px;
			}
		}
	}
}
.footer__content {
	padding-top: 16px;
	padding-bottom: 16px;
	color: #fff;
	background-color: $black;

	@include wide {
		padding-top: 26px;
		padding-bottom: 26px;
	}
}
.footer__content__inner {
	@include wide {
		display: flex;
		flex-direction: row-reverse;
		justify-content: space-between;
	}
}
.footer__link {
	text-align: center;
	a {
		font-size: 1.2rem;
		position: relative;
		display: inline-flex;
		align-items: center;
		color: $warm_gray;
		&:before {
			display: block;
			width: 8px;
			height: 8px;
			margin-top: 2px;
			margin-right: 10px;
			content: "";
			transform: rotate(45deg);
			border: solid $red;
			border-width: 1px 1px 0 0;
		}
		&:after {
			position: absolute;
			right: 0;
			bottom: 2px;
			display: block;
			width: calc(100% - 18px);
			height: 1px;
			content: "";
			transition: opacity .4s;
			opacity: 0;
			background: $white;
		}
		&:hover:after {
			@include wide {
				opacity: 1;
			}
		}
	}
}
.footer__list {
	@include wide {
		display: flex;
	}
	li {
		@include wide {
			margin-right: 10px;
		}
	}
}
.footer__copyright {
	font-family: $font_barlow_c;
	text-align: center;
	letter-spacing: .05em;
	color: $dark_gray;

	@include sp {
		margin-top: 22px;
	}
	@include wide {
		font-size: 1.6rem;
	}
}

.footer-link {
	background-color: $white;
	padding-bottom: 6rem;
	&__inner {
		width: 90%;
		margin: 0 auto;
		max-width: 113rem;
		@include wide {
			display: flex;
			justify-content: space-between;
		}
	}
	&__logo {
		display: block;
		width: 20rem;
		margin: 0 auto 4rem;
		@include wide {
			margin: 0 auto 6rem;
		}
	}
	&__logo-wrap {
		margin-bottom: 5rem;
		@include wide {
			margin: 3.55rem 12.3% 0 0;
		}
	}
	&__sns-list {
		display: flex;
		justify-content: space-between;
		width: 17.1rem;
		margin: 0 auto 4rem;
		@include wide {
			margin: 0 auto 6rem;
		}
	}
	&__sns-item {
		width: 4.1rem;
	}
	&__sns-link {
		display: block;
		width: 100%;
	}
	&__sns-img {
		display: block;
		width: 100%;
	}
	&__blog-btn {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 12.4rem;
		height: 3rem;
		border: 1px solid #E3DBDB;
		margin: 0 auto;
		@include wide {
			width: 11.4rem;
			height: 4.1rem;
		}
	}
	&__blog-btn-text {
		font-size: 1.4rem;
		font-weight: 500;
		letter-spacing: 0.14rem;
		&::after {
			position: relative;
			top: -0.8rem;
			display: inline-block;
			content: '';
			background-image: url(/assets/img/common/icon-external-black.svg);
			background-size: cover;
			background-repeat: no-repeat;
			width: 0.75rem;
			height: 0.75rem;
			margin-left: 0.2rem;
		}
	}
	&__list {
		@include wide {
			width: 32%;
		}
		& + & {
			margin-top: 4rem;
			@include wide {
				margin-top: 0;
				margin-left: 6%;
			}
		}
	}
	&__item {
		& + & {
			margin-top: 4rem;
		}
	}
	&__url {
		display: inline-block;
		font-size: 1.5rem;
		font-weight: 700;
		line-height: 1.6;
		letter-spacing: 0.12rem;
		&:hover {
			@include wide {
				text-decoration: underline;
			}
		}
	}
	&__item-list {
		display: flex;
		flex-wrap: wrap;
	}
	&__item-item {
		position: relative;
		padding-right: 1.6rem;
		margin-right: 1.6rem;
		margin-top: 1rem;
		&::before {
			content: "";
			position: absolute;
			top: 50%;
			right: 0;
			transform: translateY(-50%);
			width: 1px;
			height: 100%;
			background-color: $dark_gray;
		}
		&:last-of-type {
			padding-right: 0;
			margin-right: 0;
			&::before {
				display: none;
			}
		}
	}
	&__item-url {
		font-size: 1.4rem;
		font-weight: 500;
		letter-spacing: 0.14rem;
		&:hover {
			@include wide {
				text-decoration: underline;
			}
		}
	}
}