@use "../base/summarize" as * ;
//共通
.careers-division-detail-heading {
	letter-spacing: .1em;
	@include sp {
		font-size: 2.4rem;
		margin-bottom: 40px;
		padding-left:$side_padding_sp;
		padding-right:$side_padding_sp;
	}
	@include wide {
		font-size: 2.8rem;
		max-width: 1200px;
		margin-right: auto;
		margin-left: auto;
	}
}

//ここからcareers-division-detail-mv
.careers-division-detail-mv {
	position: relative;

	@include wide {
		height: 300px;
	}
	.under-mv__heading {
		font-size: 2.4rem;
		line-height: 1.75;
		position: relative;
		letter-spacing: .1em;
		color: $black;

		@include wide {
			font-size: 3.6rem;
			letter-spacing: .1em;
		}
	}
}
.careers-division-detail-mv__text {
	font-size: 1.6rem;
	display: block;
	letter-spacing: .09em;

	@include wide {
		font-size: 2rem;
		letter-spacing: .05em;
	}
}
.careers-division-detail-mv__button {
	top: 210px;

	@include wide {
		top: 262px;
	}
}

//ここからcareers-division-detail-top
.careers-division-detail-top {
	background: #fff;
}
.careers-division-detail-top__inner {
	padding-top: 47px;
	padding-bottom: 45px;

	@include wide {
		max-width: 1088px;
		padding-top: 98px;
		padding-bottom: 128px;
	}
}
.careers-division-detail-top__textarea {
	margin-bottom: 55px;

	@include wide {
		margin-bottom: 72px;
	}
}
.careers-division-detail-top__textarea:last-of-type {
	margin-bottom: 0;
}
.careers-division-detail-top__heading {
	margin-bottom: 22px;

	@include wide {
		font-size: 2.8rem;
	}
}
.careers-division-detail-top__sub-heading {
	font-size: 2.0rem;
	line-height: 1.5;
	margin-bottom: 20px;
	letter-spacing: .05em;

	@include wide {
		font-size: 2.4rem;
	}
	&:not(:first-child) {
		margin-top: 40px;
	}
	.careers-division-detail-top__heading +& {
		margin-top: 20px;
	}
}
.careers-division-detail-top__text {
	font-size: 1.8rem;
	line-height: 1.75;
	letter-spacing: .02em;
	& +& {
		margin-top: 30px;
		&--narrow-margin {
			margin-top: 20px;
		}
	}
}
.careers-division-detail-top__indent {
	display: block;
	padding-left: 1em;
	text-indent: -1em;
	& +& {
		margin-top: .2em;
	}
}

//ここからcareers-division-detail-gallery
.careers-division-detail-gallery__inner {
	@include sp {
		padding: 53px 0 78px;
	}
	@include wide {
		max-width: 100%;
		padding-top: 86px;
		padding-bottom: 110px;
	}
}
.careers-division-detail-gallery__heading {
	margin-bottom: 30px;

	@include wide {
		margin-bottom: 54px;
	}
}



//ここからcareers-division-detail-interview
.careers-division-detail-interview {
	background: $white;
	&--bg {
		position: relative;
		&::before {
			content: '';
			position: absolute;
			bottom: 70px;
			left: 0;
			width: 100%;
			height: 37.5%;
			background-color: $black;
			@include wide {
				bottom: 150px;
				height: 43%;
			}
		}
		.careers-division-detail-interview__inner {
			padding-top: 0;
			padding-bottom: calc(90px + 70px);

			@include wide {
				padding-top: 0;
				padding-bottom: calc(180px + 150px);
			}
		}
	}
}
.careers-division-detail-interview__inner {
	padding-top: 56px;
	padding-bottom: 100px;

	@include wide {
		padding-top: 86px;
		padding-bottom: 86px;
	}
}
.careers-division-detail-interview__section {
	position: relative;
	& +& {
		margin-top: 80px;

		@include wide {
			margin-top: 90px;
		}
	}
}
.careers-division-detail-interview__heading {
	margin-bottom: 42px;

	@include wide {
		margin-bottom: 44px;
	}
}
.careers-division-detail-interview__title {
	font-size: 2rem;
	line-height: 1.5;
	margin-bottom: 30px;
	letter-spacing: .05em;

	@include wide {
		font-size: 2.4rem;
	}
}

//ここからcareers-division-detail-position
.careers-division-detail-position__inner {
	padding-top: 58px;
	padding-bottom: 58px;

	@include wide {
		padding-top: 77px;
		padding-bottom: 100px;
	}
}
.careers-division-detail-position__heading {
	margin-bottom: 29px;
	text-align: center;

	@include wide {
		margin-bottom: 33px;
	}
}
.careers-division-detail-position__button {
	width: 100%;
	max-width: 124px;
	height: 40px;

	@include wide {
		max-width: none;
	}
}


//ここからcareers-division-detail-back
.careers-division-detail-back {
	margin-top: 0;
	padding-top: 86px;
	padding-bottom: 86px;
	background: #fff;

	@include wide {
		padding-top: 82px;
		padding-bottom: 99px;
	}
}
