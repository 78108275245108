@use "../base/summarize" as * ;
//ここからcontact-top
.contact-top {
	margin-bottom: 62px;
	// padding-top: 36px;

	@include pc {
		margin-bottom: 45px;
		// padding-top: 56px;
	}
}
.contact-top__inner {
	width: 100%;
	margin: 0 auto;

	@include tab {
		max-width: 600px;
	}
}
.contact-top__heading {
	margin-bottom: 37px;
	text-align: center;

	@include wide {
		margin-bottom: 24px;
		// letter-spacing: .08em;
	}
}
.contact-top__text {
	font-size: 1.6rem;
	line-height: 1.75;
	margin-bottom: 16px;

	@include pc {
		margin-bottom: 18px;
		text-align: center;
	}
}
.contact-top__text-annotation {
	font-size: 1.2rem;
	text-align: left;
	letter-spacing: 0;

	@include pc {
		font-size: 1.4rem;
		text-align: center;
	}
}
.contact-top__link {
	font-weight: bold;
	color: $red;
}

//ここからcontact-form
.contact-form {
	margin-bottom: 52px;

	@include pc {
		margin-bottom: 64px;
	}
}
.contact-form__inner {
	@include pc {
		max-width: 1086px;
	}
	@include tab {
		max-width: 600px;
	}
}
.contact-form__box {
	margin-bottom: 24px;

	@include pc {
		display: flex;
		margin-bottom: 16px;
	}
}
.contact-form__box-2 {
	margin-bottom: 18px;

	@include pc {
		margin-bottom: 14px;
	}
}
.contact-form__box-4 {
	margin-bottom: 16px;
}
.contact-form__box-5 {
	margin-bottom: 24px;

	@include pc {
		margin-bottom: 20px;
	}
}
.contact-form__box:last-of-type {
	margin-bottom: 0;
}
.contact-form__head {
	margin-bottom: 12px;
}
.contact-form__head-sub {
	margin-bottom: 4px;
}
.contact-form__head-text {
	font-size: 1.6rem;
	font-weight: bold;
	position: relative;
	display: flex;
	align-items: center;
	height: 40px;

	@include pc {
		width: 256px;
		margin-left: 54px;
		letter-spacing: .06em;
	}
}
.contact-form__head-mandatory {
	margin-left: 52px;

	@include pc {
		margin-left: 54px;
	}
}
.contact-form__head-mandatory::before {
	font-size: 1.2rem;
	position: absolute;
	top: 50%;
	left: -52px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	content: "必須";
	transform: translateY(-50%);
	color: #fff;
	border-radius: 50%;
	background: #100f18;

	@include pc {
		top: 56%;
		left: -54px;
	}
}
.contact-form__name {
	width: 100%;
}
.contact-form__name-sub {
	@include pc {
		max-width: 368px;
	}
}
.contact-form__name-select {
	position: relative;
}
.mw_wp_form_input .contact-form__name-select::before {
	position: absolute;
	z-index: 2;
	top: 42%;
	right: 17px;
	display: block;
	width: 7px;
	height: 7px;
	content: "";
	transform: rotate(45deg);
	border-right: 1px solid #e60000;
	border-bottom: 1px solid #e60000;

	@include pc {
		top: 38%;
	}
}
.contact-form__name select {
	width: 100%;
	height: 48px;
	padding: 0 14px;
	cursor: pointer;
	letter-spacing: .06em;
	color: #100f18;
	border: 1px solid #e3dbdb;
	background: #f9f9f9;

	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}
.contact-form__name select:focus {
	border: 1px solid #958f8f;
}
.contact-form__name select option {
	font-size: 1.4rem;
}
.contact-form__name textarea {
	width: 100%;
	height: 80px;
	padding: 14px;
	border: 1px solid #e3dbdb;
	background: #f9f9f9;

	@include pc {
		height: 96px;
	}
}
.contact-form__name textarea:focus {
	border: 1px solid #958f8f;
}
.contact-form__name input {
	width: 100%;
	height: 48px;
	padding: 0 14px;
	border: 1px solid #e3dbdb;
	background: #f9f9f9;
}
.contact-form__name input:focus {
	border: 1px solid #958f8f;
}

//ここからcontact-bottom
.contact-bottom__inner {
	@include pc {
		max-width: 1080px;
	}
	@include tab {
		max-width: 600px;
	}
}
.contact-bottom__nav {
	margin-bottom: 32px;

	@include pc {
		margin-bottom: 28px;
	}
}
.contact-bottom__list {
	font-size: 1.6rem;
	line-height: 1.75;
	position: relative;
	margin-bottom: 10px;
	margin-left: 15px;

	@include pc {
		margin-bottom: 0;
	}
}
.contact-bottom__list:last-of-type {
	margin-bottom: 0;
}
.contact-bottom__list::before {
	position: absolute;
	left: -15px;
	content: "・";
}
.contact-bottom__text {
	font-size: 1.6rem;
	line-height: 1.75;
	margin-bottom: 80px;

	@include pc {
		margin-bottom: 72px;
	}
}
.contact-bottom__link {
	font-weight: bold;
	color: $red;
}
.contact-bottom__button {
	margin-right: auto;
	margin-bottom: 74px;
	margin-left: auto;
	cursor: pointer;
}
