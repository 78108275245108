@use "../base/summarize" as * ;
.news-list {
	padding-bottom: 120px;

	@include wide {
		padding-bottom: 186px;
	}
}
.news-list__menu {
	margin-top: 15px;

	@include wide {
		margin-top: 30px;
	}
}
.news-list__articles {
	margin-top: 20px;

	@include wide {
		margin-top: 40px;
	}
}
