html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
picture {
	margin: 0;
	padding: 0;
	vertical-align: bottom;
	border: 0;
	outline: 0;
	background: transparent;

	-webkit-text-size-adjust: none;
}
*,
*::before,
*::after {
	box-sizing: border-box;
}

body {
	line-height: 1;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main,
picture {
	display: block;
}
ol,
ul,
li {
	list-style: none;
}
blockquote,
q {
	quotes: none;
}
:focus {
	outline: 0;
}
ins,
a {
	text-decoration: none;
}
del {
	text-decoration: line-through;
}
table {
	border-spacing: 0;
	border-collapse: collapse;
}
