@use "../base/summarize" as * ;
.error-section {
	padding-bottom: 70px;
	text-align: center;

	@include wide {
		padding-bottom: 100px;
	}
}
.error-section__heading {
	margin-bottom: 20px;
	letter-spacing: .02em;

	@include wide {
		margin-bottom: 30px;
	}
}
.error-section__text {
	font-size: 1.6rem;
	display: inline-block;

	@include sp {
		text-align: left;
	}
}
.error-section__button {
	margin-top: 30px;
	margin-right: auto;
	margin-left: auto;

	@include wide {
		margin-top: 50px;
	}
}
