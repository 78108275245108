// color
$white: #fff;
$black: #100f18;
$red: #e60000;
$bg_gray: #f9f9f9;
$bg_blue: #edf1f9;
$warm_gray: #e3dbdb;
$dark_gray: #958f8f;

// font
$font_base: "Noto Sans JP", sans-serif;
$font_barlow_c: "Barlow Condensed", sans-serif;
$font_barlow: "Barlow", sans-serif;
$font_roboto: "Roboto", sans-serif;
$font_kurenaido: "Zen Kurenaido", sans-serif;
// size
$header_height_sp: 56px;
$header_height_pc: 114px;
$side_padding_sp: 20px;
$side_padding_pc: 48px;

// style
$box_shadow: 10px 10px 40px rgba(16, 15, 24, .1);
$box_shadow_hover: 20px 20px 30px rgba(#100f18, .15);
